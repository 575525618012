(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('alertService', alertService);

    alertService.$inject = [
        '$mdToast',
    ];

    function alertService ($mdToast) {
        let alerts = [];

        //noinspection UnnecessaryLocalVariableJS
        const service = {
            new: {
                error: (msg, timeout) => { toaster('error', msg, timeout) },
                generic: (msg, timeout) => { toaster('generic', msg, timeout) },
                success: (msg, timeout) => { toaster('success', msg, timeout) },
            },
        };

        return service;

        ////////////

        function toaster (type, msg, timeout) {
            let classes;
            const delay = (timeout === 0) ? 0: timeout || 3200;
            const ifInfiniteShowCloseBtn = (timeout === 0) ? '✕': undefined;

            switch (type) {
                case 'success':
                    classes = 'md-middle md-toast-success';
                    break;
                case 'error':
                    classes = 'md-middle md-toast-error';
                    break;
                default:
                    classes = 'md-middle';
                    break;
            }

            $mdToast.show(
                $mdToast.simple()
                    .textContent(msg)
                    .position('top right')
                    .toastClass(classes)
                    .hideDelay(delay)
                    .action(ifInfiniteShowCloseBtn)
            );
        }
    }
})();
