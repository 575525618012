// eslint-disable-next-line func-names, func-style
(function () {
    'use strict';

    angular
        .module('app.layout')
        .run(appRun);

    appRun.$inject = [
        'authService',
        'routerHelper',
    ];

    /* @ngInject */
    function appRun (authService, routerHelper) {

        routerHelper.configureStates(getStates(authService));

        function getStates (authService) {
            return [
                {
                    state: 'default',
                    config: {
                        abstract: true,
                        views: {
                            header: 'ulmHeader',
                            footer: 'ulmFooter',
                        },
                        resolve: {
                            userData: authService.getAuth,
                        },
                    },
                },
                {
                    state: 'private',
                    config: {
                        abstract: true,
                        views: {
                            header: 'ulmHeader',
                            footer: 'ulmFooter',
                        },
                        resolve: {
                            isActive: authService.isActive,
                            hasPrimaryDomain: authService.hasPrimaryDomain,
                            hasShortlinkDomain: authService.hasShortlinkDomain,
                            userData: authService.getAuth,
                            user: authService.requireAuth,
                        },
                    },
                },
            ];
        }
    }

}());
