(function () {
    'use strict';

    angular
        .module('app')
        .config(config);

    config.$inject = [
        '$provide',
        '$mdThemingProvider',
        'featureFlagsProvider',
        'routerHelperProvider',
    ];

    /* @ngInject */
    function config ($provide, $mdThemingProvider, featureFlagsProvider, routerHelperProvider) {
        routerHelperProvider.configure({ docTitle: `UTM Link Manager | ` });

        featureFlagsProvider.setInitialFlags([
            {
                key: 'shortlinks',
                active: true,
                name: 'Branded Shortlinks',
                description: "All branded shortlink functionality"
            },
            {
                key: 'manage',
                active: false,
                name: 'Account Management',
                description: "Self-serve account management and information"
            },
        ]);

        // TODO: figure out if the upgraded firebase handles this stuff on its own...

        firebaseDecorator.$inject = ['$delegate'];

        $provide.decorator("$firebaseArray", firebaseDecorator);
        $provide.decorator("$firebaseObject", firebaseDecorator);

        function firebaseDecorator ($delegate) {
            return function (ref) {
                const list = $delegate(ref);
                window.openFirebaseConnections.push(list);
                return list;
            };
        }

        const customPrimary = {
            '50': '#ffb8b1',
            '100': '#ffa198',
            '200': '#ff897e',
            '300': '#ff7265',
            '400': '#ff5a4b',
            '500': '#ff4940',
            '600': '#ff4331',
            '700': '#fe1500',
            '800': '#e41300',
            '900': '#cb1100',
            'A100': '#ffcfcb',
            'A200': '#ffe7e4',
            'A400': '#fffefe',
            'A700': '#b10f00',
            'contrastDefaultColor': 'light'
        };
        $mdThemingProvider.definePalette('customPrimary', customPrimary);

        const customAccent = {
            '50': '#0f3d58',
            '100': '#134c6e',
            '200': '#165b84',
            '300': '#1a6a99',
            '400': '#1e79af',
            '500': '#2288c5',
            '600': '#3ca1de',
            '700': '#52ace1',
            '800': '#67b6e5',
            '900': '#7dc1e9',
            'A100': '#3ca1de',
            'A200': '#2697da',
            'A400': '#2288c5',
            'A700': '#93cbed',
            'contrastDefaultColor': 'light'
        };
        $mdThemingProvider.definePalette('customAccent', customAccent);

        const customWarn = {
            '50': '#ffd7b1',
            '100': '#ffc998',
            '200': '#ffbc7e',
            '300': '#ffaf65',
            '400': '#ffa14b',
            '500': '#ff9432',
            '600': '#ff8718',
            '700': '#fe7900',
            '800': '#e46d00',
            '900': '#cb6100',
            'A100': '#ffe4cb',
            'A200': '#fff1e4',
            'A400': '#fffefe',
            'A700': '#b15500',
            'contrastDefaultColor': 'light'
        };
        $mdThemingProvider.definePalette('customWarn', customWarn);

        $mdThemingProvider.theme('default')
            .primaryPalette('customPrimary')
            .accentPalette('customAccent')
            .warnPalette('customWarn');

        $mdThemingProvider.theme('altTheme')
            .primaryPalette('customAccent')
            .accentPalette('customPrimary')
            .warnPalette('customWarn');

        $mdThemingProvider.theme('grey')
            .primaryPalette('grey');
    }
}());
