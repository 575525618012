(function () {
    'use strict';

    angular
        .module('app.library')
        .controller('LinkModalController', LinkModalController);

    LinkModalController.$inject = [
        '$cookies',
        '$log',
        '$mdDialog',
        '$filter',
        'alertService',
        'data',
        'domainService',
        'linkService',
        'presetService',
        'shortlinkService',
    ];

    /* @ngInject */
    function LinkModalController ($cookies, $log, $mdDialog, $filter, alertService, data, domainService, linkService, presetService, shortlinkService) {
        const vm = this;
        vm.cancel = () => $mdDialog.cancel();
        vm.domains = domainService.getDomains();
        vm.link = data;
        vm.links = linkService.getLinksByUser();
        vm.loading = false;
        vm.newLink = new linkService.Link();
        vm.presets = presetService.getPresetsByUser();
        vm.state = {};
        vm.state.hasMultipleDomains = true;
        vm.state.searchText = {};
        vm.state.timestamp = $filter('date')(new Date(),'yyyy-MM-dd-HHmm');
        vm.state.title = 'LinkModalController';
        vm.submitButtonText = () => (vm.loading) ? 'Creating...' : 'Create Link';
        vm.submitLinkForm = submitLinkForm;
        vm.autoUtmContent = autoUtmContent;
        vm.toggleHelp = toggleHelp;

        activate();

        // --------- //

        function activate () {
            const val = $cookies.get('showHelp');
            vm.state.showHelp = val === true || val === 'true';

            vm.domains.$loaded().then(() => {
                vm.state.hasMultipleDomains = vm.domains.length > 1;
                if (vm.state.hasMultipleDomains && !vm.link) { vm.newLink.domain = vm.domains[0].baseDomain; } // select first domain if multi-domain and !vm.link
            });

            if (vm.link) { // Duplicating existing link to be shown in the form and ready to go
                angular.copy(vm.link, vm.newLink);
                delete vm.newLink.thumbnail;
                vm.newLink.shortlink = ''; // clear shortlink as it's for the old URL
                vm.newLink.dateAdded = Math.floor(Date.now() / 1000); // update timestamp since this is a new link
                vm.newLink.domain = $filter('encodeDots')(vm.newLink.domain.split('//').pop()); // reverse link creation so that domain field shows properly
            }
        }

        function autoUtmContent () {
            vm.newLink.utmContent = vm.state.timestamp;
        }

        function submitLinkForm () {
            let linkInProgress = {};
            vm.loading = true;
            angular.copy(vm.newLink, linkInProgress);

            if (linkInProgress.domain) {
                if (!linkInProgress.domain.startsWith('http')) {
                    // add protocol and decodeDots if needed (happens when new link and account has multiple domains)
                    const indexOfDomain = vm.domains.findIndex(domain => domain.baseDomain === linkInProgress.domain);
                    linkInProgress.domain = `${vm.domains[indexOfDomain].protocol}${ $filter('decodeDots')(linkInProgress.domain) }`;
                }
            } else {
                // add domain data if not provided (happens when account only has single domain)
                linkInProgress.domain = `${vm.domains[0].protocol}${ $filter('decodeDots')(vm.domains[0].baseDomain) }`;
            }

            // Pull raw values out of <md-autocomplete> objects where used & use vm.state.searchtext value otherwise
            Object.keys(linkInProgress).forEach((key) => {
                if (key.startsWith('$')) return;

                let val = linkInProgress[key];
                if (val && typeof val === 'object') return linkInProgress[key] = val.label;
                if (!val && vm.state.searchText.hasOwnProperty(key)) return linkInProgress[key] = vm.state.searchText[key]; // if there's no object as the value, see if there's something in the searchText (i.e. handle a non-match in <md-autocomplete>)
            });

            if (linkService.isDuplicate(linkInProgress, vm.links)) {
                alertService.new.error('Oops... a link just like that one already exists', 0);
                // todo: also add class that makes modal shake indicating something went wrong
                vm.loading = false;
            } else {
                linkService.addNewLink(vm.domains, vm.links, linkInProgress, shortlinkService) //call link service method
                    .then(() => {
                        vm.loading = false;
                        vm.state.isDuplicate = false;
                        vm.newLink = new linkService.Link();
                        $mdDialog.hide('link created');
                    });
                // todo: dismiss open toast to avoid confusion b/w loading & prev error (track open toasts w/in alertService to allow a alertService.dismiss() method)
            }
        }

        function toggleHelp () {
            $log.info('running toggleHelp()');
            $cookies.put('showHelp', vm.state.showHelp);
        }
    }
}());
