(function () {
    'use strict';

    angular
        .module('app.library')
        .component('library', library());

    function library () {
        return {
            bindings: {
                user: '=',
            },
            controller: LibraryController,
            controllerAs: 'vm',
            templateUrl: 'app/library/library.html',
        };
    }

    LibraryController.$inject = [
        'linkService',
        'modalService',
    ];

    function LibraryController (linkService, modalService) {
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        vm.links = linkService.getLinksByUser();
        vm.openModal = modalService.create('app/library/components/linkModal.html', 'LinkModalController');
    }
}());
