(function () {
    'use strict';

    angular
        .module('app')
        .run(run);

    run.$inject = [
        '$log',
        '$timeout',
        '$transitions',
        'authService',
    ];

    /* @ngInject */
    function run ($log, $timeout, $transitions, authService) {
        authService.pushUserDataToGTM();
    }
}());
