(function () {
    'use strict';

    angular.module('app.billing', ['stripe']).run(run);

    run.$inject = ['STRIPE_PUBLISHABLE_KEY'];

    function run (STRIPE_PUBLISHABLE_KEY) {
        Stripe.setPublishableKey(STRIPE_PUBLISHABLE_KEY);
    }
})();
