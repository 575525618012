// eslint-disable-next-line func-names, func-style
(function () {
    'use strict';

    angular
        .module('app.layout')
        .component('ulmHeader', ulmHeader());

    function ulmHeader () {
        return {
            controller: HeaderController,
            controllerAs: 'vm',
            templateUrl: 'app/layout/components/header.html',
            bindings: {
                userData: '=',
            },
        };
    }

    HeaderController.$inject = [
        '$log',
        'authService',
    ];

    /* @ngInject */
    function HeaderController ($log, authService) {
        const vm = this;
        vm.logout = authService.logout;
    }
}());
