// eslint-disable-next-line func-names, func-style
(function () {
    'use strict';

    angular
        .module('app.core')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        const otherwise = '/library';
        routerHelper.configureStates(getStates(), otherwise);
    }

    function getStates () {
        return [
            {
                state: '404',
                config: {
                    url: '/404',
                    title: 'Page Not Found',
                    parent: 'default',
                    views: {
                        'content@': {
                            templateUrl: 'app/core/404.html',
                        },
                    },
                },
            },
        ];
    }
}());

