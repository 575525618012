(function () {
    'use strict';

    angular
        .module('app.domains')
        .component('hmDomainsTable', hmDomainsTableOptions());

    function hmDomainsTableOptions () {
        return {
            bindings: {
                domains: '=',
                shortlinkDomains: '=',
            },
            controller: DomainsTableController,
            controllerAs: 'vm',
            templateUrl: 'app/domains/components/hmDomainsTable.html',
        }
    }

    DomainsTableController.$inject = [
        '$log',
        '$q',
        '$scope',
        'alertService',
        'confirmService',
        'shortlinkService',
    ];

    /* @ngInject */
    function DomainsTableController ($log, $q, $scope, alertService, confirmService, shortlinkService) {
        var vm = this;
        vm.confirmThenDelete = confirmThenDelete;
        vm.removeDomain = removeDomain;
        vm.saveRow = saveRow;
        vm.state = {};
        vm.state.mdOrder = '-';
        vm.state.configuredShortlinkDomains = undefined;
        vm.verificationLinkHitCount = verificationLinkHitCount;

        activate();

        ///////

        function activate () {
            vm.state.loading = $q(resolve => { resolve() })
                .then(() => { vm.state.loading = false });
            $log.info('Activated DomainsTableController()');

            $scope.$watch('vm.shortlinkDomains.length', (newValue, oldValue, scope) => {
                vm.state.configuredShortlinkDomains = newValue;
            });
        }

        function confirmThenDelete (e, target) {
            const action = vm.removeDomain;
            const question = 'Do you want to permanently delete this domain?';
            const toastMsg = 'Domain deleted';

            confirmService.confirmThenToast(e, question, action, target, toastMsg);
        }

        function removeDomain (domain) {
            if (domain.$id !== 'domain0') {
                vm.domains.$remove(domain);
            }
        }

        function saveRow (item) {
            vm.domains.$save(item).then(function () {
                alertService.new.generic('Changes saved');
            });
        }

        /**
         * Used to check whether a given shortlink domain has been verified
         * @param safeDomain - comma-encoded shortlink domain
         * @returns {number} - number of visits registered to the verification link
         */
        function verificationLinkHitCount (safeDomain) {
            return shortlinkService.verifyCount(safeDomain);
        }
    }
})();
