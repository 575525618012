(function () {
    'use strict';

    angular
        .module('app.auth')
        .factory('authService', authService);

    authService.$inject = [
        '$analytics',
        '$firebaseAuth',
        '$firebaseObject',
        '$http',
        '$location',
        '$log',
        '$q',
        '$state',
        '$window',
        'alertService',
        'domainService',
        'firebaseDataService',
        'LAMBDA_KEY',
        'POST_REGISTRATION_LAMBDA_URL'
    ];

    function authService ($analytics, $firebaseAuth, $firebaseObject, $http, $location, $log, $q, $state, $window, alertService, domainService, firebaseDataService, LAMBDA_KEY, POST_REGISTRATION_LAMBDA_URL) {
        let domains = null;
        // defined here due to dependencies
        const firebaseAuthObject = $firebaseAuth();

        const service = {
            addToMarketingAutomation,
            // addUser: function (user) { return firebaseAuthObject.$createUserWithEmailAndPassword(user.email, user.password) },
            authData: function () { return firebaseAuthObject.$getAuth() },
            firebaseAuthObject,
            getAuth: firebaseAuthObject.$waitForSignIn,
            getEmail: function () { return $firebaseAuth().$getAuth().email },
            getProfile,
            getUID: function () { return firebaseAuthObject.$getAuth().uid },
            hasPrimaryDomain,
            hasShortlinkDomain,
            isActive,
            isLoggedIn,
            login,
            lost,
            logout,
            pushUserDataToGTM,
            register,
            requireAuth: firebaseAuthObject.$requireSignIn,
            updateProfile,
        };

        return service;

        ////////////

        function addToMarketingAutomation (user) {
            const authData = service.authData();
            // todo: rename to be something related to postRegistrationHandler && run along with service.register()
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': LAMBDA_KEY
                }
            };

            $http.post(POST_REGISTRATION_LAMBDA_URL, {
                user: user,
                uid: authData.uid
            }, config, successCallback, errorCallback);

            function errorCallback (response) {
                console.log('ERROR', response);
                $log.error(response);
                // todo: handle error
            }

            function successCallback (response) {
                $log.info(response);
                // todo: handle success
            }
        }

        function getProfile () {
            const obj = $firebaseObject(firebaseDataService.userProfile);
            return obj.$loaded();
        }

        function hasPrimaryDomain () {
            return $q((resolve, reject) => {
                if (!isLoggedIn()) { return resolve() } // if they're not logged in, they'll be redirected if auth is required

                firebaseDataService.userProfile.child('domains')
                    .once('value', snap => {
                        if (snap.val()) {
                            resolve();
                        } else {
                            reject('MISSING_DOMAIN');
                        }
                    });
                ;

            })
        }

        function hasShortlinkDomain() {
            return firebaseAuthObject.$waitForSignIn()
                .then(() => {
                    return $q((resolve, reject) => {
                        firebaseDataService.user.child('shortlinkDomains').once('value', snap => {
                            if (snap.exists()) resolve();
                            reject('MISSING_SHORTLINK_DOMAIN');
                        });
                    });
                });
        }

        function isActive () {
            return firebaseAuthObject.$waitForSignIn()
                .then(() => {
                    return $q((resolve, reject) => {
                        firebaseDataService.user.child('active').once('value', snap => {
                            if (snap.val()) {
                                resolve();
                            } else {
                                reject('ACCOUNT_DISABLED');
                            }
                        });
                    })
                });
        }

        function isLoggedIn () {
            return service.authData();
        }

        function login (user) {
            return firebaseAuthObject.$signInWithEmailAndPassword(user.email, user.password)
                .then((firebaseUser) => {
                    $log.info('Signed in as:', firebaseUser.uid);
                    service.pushUserDataToGTM();
                    alertService.new.success('Welcome back!');
                })
        }

        function lost (email) {
            return firebaseAuthObject.$sendPasswordResetEmail(email)
        }

        function logout () {
            // destroy all firebase refs
            angular.forEach($window.openFirebaseConnections, function (value, key) {
                value.$destroy();
            });
            firebaseAuthObject.$signOut();
            $location.path('/');

            // todo: removed because it doesn't get seen when used with the ULM-28 fix below
            // alertService.new.generic('You've been logged out, see you again soon');

            // fixes ULM-28 but not in the most graceful way...
            window.location.reload();
        }

        function pushUserDataToGTM () {
            if (service.isLoggedIn()) {
                service.getProfile().then(function (profile) {
                    $window.dataLayer.push({
                        'user.uid': service.getUID(),
                        'user.first': profile.fname,
                        'user.last': profile.surname,
                        'user.email': profile.email,
                    });
                    $window.dataLayer.push({ 'event': 'user-data-added' });
                });
            }
        }

        function register (user) {
            $log.info('Running register() method in authService');
            return firebaseAuthObject.$createUserWithEmailAndPassword(user.email, user.password)
                .then(function () {
                    $log.info('logging new user into ULM');
                    return service.login(user)
                        .then(() => { $state.go('welcome', undefined, { reload: true }) });
                })
                .then(function () {
                    $analytics.eventTrack('registered', {
                        category: 'goals',
                        label: service.getUID()
                    });
                    service.updateProfile(user);
                    alertService.new.success('Congratulations, and thanks for joining UTM Link Manager! Everything is ready for you to get started');
                    return service.addToMarketingAutomation(user);
                })
        }

        function updateProfile (user) {
            let domainSet = new domainService.DomainSet(user.domain.baseDomain, user.domain.protocol);
            firebaseDataService.userProfile.set({
                email: user.email,
                business: user.business,
                domains: {
                    domain0: domainSet
                },
                type: 'saas',
                industry: user.type,
                fname: user.fname,
                surname: user.surname
            });

            $log.debug('user', user);
            firebaseDataService.user.child('billing').set(user.billingData);
            firebaseDataService.user.child('email').set(user.email);
            firebaseDataService.user.child('active').set(true);
        }
    }
})();
