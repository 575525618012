(function () {
    'use strict';

    angular
        .module('app.shortlinks')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'shortlinks',
                config: {
                    url: '/shortlinks',
                    title: 'Link Shortener',
                    parent: 'private',
                    views: {
                        'content@': {
                            component: 'shortlinks',
                        }
                    }
                }
            }
        ];
    }
}());
