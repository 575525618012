// eslint-disable-next-line func-names, func-style
(function () {
    'use strict';

    angular
        .module('app.auth')
        .component('login', login());

    function login () {
        return {
            bindings: {},
            controller: LoginController,
            controllerAs: 'vm',
            templateUrl: 'app/auth/components/login.html',
        };
    }

    LoginController.$inject = [
        '$log',
        '$state',
        '$scope',
        'authService',
    ];

    /* @ngInject */
    function LoginController ($log, $state, $scope, authService) {
        const vm = this;
        vm.loginHandler = loginHandler;
        vm.state = {};
        vm.state.loading = false;
        vm.state.title = 'LoginController';
        vm.submitButtonText = submitButtonText;
        vm.user = {};
        vm.lostPassword = lostPassword;


        activate();

        // --------- //

        function activate () {
            $log.info('Running', vm.state.title);
        }

        function loginHandler () {
            if ($scope.authForm.$valid) {
                // Clear errors if there are any
                delete vm.state.error;

                // Set state as loading
                vm.state.loading = true;

                authService.login({ email: vm.user.email, password: vm.user.password })
                    .then(() => {
                        $state.go('library', undefined, { reload: true });
                        /* todo: reset the form... */
                        vm.state.loading = false;
                    })
                    .catch((error) => {
                        $log.error('Authentication failed:', error);
                        vm.state.error = error;
                        vm.state.loading = false;
                    });
            }
        }

        function submitButtonText () {
            return (vm.state.loading) ? 'Loading...' : 'Log Into Your Account';
        }

        function lostPassword () {
            if (!vm.user.email) {
                vm.state.error = 'Please enter an email address to reset your password'
                return
            }
            authService.lost(vm.user.email)
                .then(() => {
                    vm.state.error = `SUCCESS: If an account exists, password reset email sent to ${vm.user.email}`
                })
        }
    }
}());
