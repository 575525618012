(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('encodeDots', encodeDots);

    function encodeDots () {
        return encodeDotsFilter;

        ////////////////

        function encodeDotsFilter (string) {
            if (!string) {
                return string;
            }

            return string.replace(/\./g, ',');
        }
    }
})();
