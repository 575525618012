(function () {
    'use strict';

    // keeps track of all open firebase connections to be $destroy'ed
    // when logging out. stored as a global variable since config doesn't
    // have $rootscope and needs to be globally accessible
    window.openFirebaseConnections = [];

    /*eslint angular/component-limit:0*/
    angular
        .module('app', [
            // Angular modules.
            'ngMaterial',
            'ngSanitize',

            // Third party modules.
            'angulartics',
            'angulartics.google.tagmanager',
            'firebase',
            'md.data.table',
            'mdSteppers',
            'angular-clipboard',
            'feature-flags',
            'blocks.router',
            'cssStateClasses',

            // Debugging
            'xhrErrorTracking',

            // Custom modules.
            'app.auth',
            'app.billing',
            'app.core',
            'app.domains',
            'app.filters',
            'app.layout',
            'app.library',
            'app.manageAccount',
            'app.pages',
            'app.presets',
            'app.services',
            'app.shortlinks',
            'app.welcome',
        ]);
})();
