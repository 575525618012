(function () {
    'use strict';

    angular
        .module('app.manageAccount')
        .component('manageAccount', manageAccount());

    function manageAccount () {
        return {
            bindings: {},
            controller: ManageAccountController,
            controllerAs: 'vm',
            templateUrl: 'app/manageAccount/manageAccount.html',
        };
    }

    ManageAccountController.$inject = [
        '$log',
        'planService',
    ];

    /* @ngInject */
    function ManageAccountController ($log, planService) {
        const vm = this;
        vm.state = {};
        vm.state.title = 'ManageAccountController';
        vm.data = {};

        activate();

        ///////

        function activate () {
            $log.info('Running', vm.state.title);

            planService.getClientType()
                .then((data) => {
                    vm.data.clientType = (data.$value === 'wso') ? 'WSO Deal' : 'Monthly Subscription';
                });

            planService.getDomainLimit()
                .then((domainLimit) => {
                    vm.data.domainLimit = domainLimit;
                });

            planService.getPlan()
                .then((data) => {
                    vm.data.plan = data.$value;
                });

            planService.getShortlinkDomainLimit()
                .then((shortlinkDomainLimit) => {
                    vm.data.shortlinkDomainLimit = shortlinkDomainLimit;
                });
        }
    }
}());
