(function () {
    'use strict';

    angular
        .module('app.pages')
        .controller('AddRequiredDetailsController', AddRequiredDetailsController);

    AddRequiredDetailsController.$inject = [
        '$log',
        '$location',
        '$scope',
        'domainService',
    ];

    function AddRequiredDetailsController ($log, $location, $scope, domainService) {
        const vm = this;
        vm.addDomain = addDomain;
        vm.domain = {};
        vm.state = {};
        vm.submitButtonText = () => (vm.state.loading) ? 'Loading...' : 'Activate My Account';

        activate();

        ///////

        function activate () {
            vm.state.loading = false;
        }

        function addDomain () {
            vm.state.loading = true;
            domainService.addPrimaryDomain(vm.domain)
                .then(() => {
                    $log.info('Primary domain added successfully');
                    $location.path('/library');
                    $scope.$apply();
                })
                .catch((err) => {
                    $log.error('Add Primary Domain Error', err);
                    // todo: display generic error to user
                    vm.state.loading = false;
                });
        }
    }
})();
