(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('shortlinkService', shortlinkService);

    shortlinkService.$inject = [
        '$filter',
        '$firebaseArray',
        '$firebaseObject',
        '$http',
        '$log',
        '$q',
        'firebaseDataService',
        'linkService',
    ];

    function shortlinkService ($filter, $firebaseArray, $firebaseObject, $http, $log, $q, firebaseDataService, linkService) {
        class Shortlink {
            constructor (shortlinkData) {
                this.dateAdded = Math.floor(Date.now() / 1000);
                this.originalUrl = shortlinkData.originalUrl;
                this.slug = shortlinkData.slug.replace(/[^\w|-]/g, ''); // remove pre-slash;
                this.visitsCount = 0;
            }

            getLink (safeDomain) {
                return 'http://' + $filter('decodeDots')(safeDomain) + '/' + this.slug;
            }
        }

        //noinspection UnnecessaryLocalVariableJS
        const service = {
            addGeneratedShortlink,
            addShortlinkDomain,
            addShortlinkForDomain,
            addShortlinkForLink,
            createGooglShortlink,
            createTheGooglShortlink,
            getRandomSlug,
            getShortlinkDomainForLink,
            getShortlinkDomains,
            getShortlinksByDomain,
            hasAvailableShortlinkDomain,
            shouldGenerateShortlinks,
            verifyCount,
        };

        return service;

        ///////

        function addGeneratedShortlink (originalUrl, shortlinkDomain) {
            return getRandomSlug(shortlinkDomain)
                .then((slug) => {
                    const theShortlink = new Shortlink({ originalUrl, slug });

                    return addShortlinkForDomain(shortlinkDomain, theShortlink)
                        .then(shortlinkString => shortlinkString);
                });
            // return promise that resolves w/ the shortlink as a string
        }

        function addShortlinkForLink (e, link, domains, links, openShortlinkModal) {
            const shortlinkDomain = getShortlinkDomainForLink(link, domains);
            const longLink = linkService.buildUTMLink(link, true); // 2. create the UTM Link (for use as the 'original link')
            if (shortlinkDomain === 'goo,gl') {
                createGooglShortlink(longLink, link, links);
            } else { // therefore it's a branded shortlink
                openShortlinkModal(e, { activeDomain: shortlinkDomain, originalLink: longLink })
                    .then((shortlinkString) => {
                        // .then save the new link into the UTM Link record
                        link.shortlink = shortlinkString;
                        links.$save(link);
                    });
            }
        }

        /**
         * Takes a proposed shortlink domain, checks if it's available to be added.
         * Then adds it to the user's account, adds to the Shortlinks database,
         * and creates a /verify shortlink for the domain
         * @param newShortlinkDomain {String}
         * @returns {Promise}
         */
        function addShortlinkDomain (newShortlinkDomain) {
            let verificationShortlinkData = {
                originalUrl: 'https://app.utmlinkmanager.com/#/shortlink-domain-check',
                slug: 'verify'
            };

            return $q((resolve, reject) => {
                // 1. check `newShortlinkDomain` isn't already setup by another account in Firebase.shortlinkDomains
                //    (else throw error and display to user via vm.stepper)
                _isShortlinkDomainUnique(newShortlinkDomain)
                    .then((isAvailable) => {
                        $log.info(`The shortlink domain is available = ${isAvailable}`);
                        if (!isAvailable) {
                            reject('duplicate');
                        } else {
                            // 2. add Shortlink (`newShortlinkDomain`/verify) to Firebase.shortlinkDomains.X
                            let addToSystemPromise = addShortlinkForDomain(newShortlinkDomain, verificationShortlinkData);

                            // 3. add ShortlinkDomain to user.shortlinkDomains
                            let addToUserAccountPromise = _addShortlinkToUserAccount(newShortlinkDomain);

                            // 4. PromiseAll based on above that then calls resolve()
                            return $q.all([addToSystemPromise, addToUserAccountPromise])
                                .then((values) => {
                                    $log.debug('values ==>', values);
                                    resolve();
                                });
                        }
                    });
            });
        }

        /**
         * @param safeDomain - comma encoded domain e.g. `www,google,com`
         * @param shortlinkData {Object|Shortlink} - object containing data needed to create a Shortlink
         * @param shortlinkData.originalUrl {String} - the URL the shortlink should point to
         * @param shortlinkData.slug {String} - the slug to be used for the shortlink
         * @returns {Promise}
         */
        function addShortlinkForDomain (safeDomain, shortlinkData) {
            return _isSlugAvailable(safeDomain, shortlinkData.slug).then(function (isAvailable) {
                if (isAvailable) {
                    const newLink = new Shortlink(shortlinkData);
                    return firebaseDataService.shortlinks.child(`${safeDomain}/${newLink.slug}`).set(newLink)
                        .then(() => {return newLink.getLink(safeDomain)});
                } else {
                    return $q(function (resolve, reject) { reject('That shortlink already exists.') });
                }
            });
        }

        /**
         * Create a Goo.gl shortlink and add it to the provided link
         * @param longLink {string}
         * @param link {object}
         * @param links
         */
        function createGooglShortlink (longLink, link, links) {
            const googleShortenerUrl = 'https://www.googleapis.com/urlshortener/v1/url/?key=AIzaSyBu8ZhuUnk23KXTQvw5Eu-kP3HUXIq1FAQ';

            $http.post(googleShortenerUrl, { longUrl: longLink })
                .success((resp) => {
                    link.shortlink = resp.id;
                    links.$save(link);
                });
        }

        /**
         * Create a goo.gl shortlink and return it as a promise
         * @param longLink
         * @return {Promise.<String>} - promise that resolves with the goo.gl shortlink as the first argument
         */
        function createTheGooglShortlink (longLink) {
            const googleShortenerUrl = 'https://www.googleapis.com/urlshortener/v1/url/?key=AIzaSyBu8ZhuUnk23KXTQvw5Eu-kP3HUXIq1FAQ';

            return $http.post(googleShortenerUrl, { longUrl: longLink })
                .success((resp) => {
                    return resp;
                });
        }


        /**
         * Recursively runs generateRandomSlug and checks against data until an unused slug is found
         * @param safeDomain - comma-encoded domain to check against
         * @param currentCount (optional) - only used when called recursively so that a running tally can be kept
         * @return {Promise.<String>} - promise that resolves with the unique slug as the first argument
         */
        function getRandomSlug (safeDomain, currentCount) {
            let count = currentCount || 1;
            let slug = _generateRandomSlug();

            return _isSlugAvailable(safeDomain, slug)
                .then(function (isAvailable) {
                    if (isAvailable) {
                        $log.info('Unused slug generated after', count, 'attempts');
                        return slug;
                    } else {
                        count++;
                        return getRandomSlug(safeDomain, count);
                    }
                });
        }

        /**
         * Get the configured shortlink domain from a given UTM link
         * @param link {object}
         * @param domains - the domains associated with the account
         * @returns shortlinkDomain {string} - comma-encoded shortlink domain (e.g. goo.gl)
         */
        function getShortlinkDomainForLink (link, domains) {
            const baseDomain = $filter('encodeDots')(link.domain.split('//').pop()); // 1. get the base domain used
            return domains.filter(domain => domain.baseDomain === baseDomain)[0].shortlinkDomain; // 2. get the shortlink domain for the domain used
        }

        function getShortlinkDomains () {
            if (!shortlinkDomains) {
                var shortlinkDomains = $firebaseArray(firebaseDataService.userShortlinkDomains);
            }

            return shortlinkDomains;
        }

        function getShortlinksByDomain (safeDomain) {
            const shortlinks = $firebaseArray(firebaseDataService.shortlinks.child(safeDomain));
            return shortlinks.$loaded();
        }

        /**
         * Determine if the user should be allowed to add a new shortlink domain to their account
         * @param shortlinkDomains
         * @param state
         */
        function hasAvailableShortlinkDomain (shortlinkDomains, state) {
            if (shortlinkDomains.length !== undefined && state.shortlinkDomainLimit !== undefined) {
                state.hasAvailableShortlinkDomain = shortlinkDomains.length < state.shortlinkDomainLimit;
            } else {
                state.hasAvailableShortlinkDomain = undefined;
            }
        }

        /**
         * Checks the domain configuration to see if automatic shortlinks is on/off
         * @param link {Link}
         * @param domains
         * @returns {Boolean}
         */
        function shouldGenerateShortlinks (link, domains) {
            const baseDomain = $filter('encodeDots')(link.domain.split('//').pop());
            const domainData = domains.filter(domain => domain.baseDomain === baseDomain)[0];
            return domainData.isAutoShortened;
        }

        function verifyCount (safeDomain) {
            return $firebaseObject(firebaseDataService.shortlinks.child(`${safeDomain}/verify`));
        }

        /**
         * @returns {Promise}
         */
        function _addShortlinkToUserAccount (shortlinkDomain) {
            return $firebaseArray(firebaseDataService.userShortlinkDomains).$add(shortlinkDomain);
        }

        function _generateRandomSlug () {
            const ALLOWED_CHARS = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
            let randomSlug = '';

            for (let i = 0; i < 4; i++) {
                randomSlug += ALLOWED_CHARS.charAt(Math.floor(Math.random() * ALLOWED_CHARS.length));
            }

            return randomSlug;
        }

        /**
         * Check if a shortlink domain is already registered in the system
         * @param safeDomain - comma encoded shortlink domain
         * @return {Promise} - returns a promise that resolves with isAvailable <Boolean>
         */
        function _isShortlinkDomainUnique (safeDomain) {
            return $q(function (resolve) {
                const ref = $firebaseArray(firebaseDataService.shortlinks);
                ref.$loaded().then(function () {
                    resolve(!Boolean(ref.$getRecord(safeDomain)));
                });
            });
        }

        /**
         * Check if a slug is already used for a shortlink domain
         * @param safeDomain - comma encoded shortlink domain
         * @param slug
         * @return {Promise} - returns a promise that resolves with isAvailable <Boolean>
         */
        function _isSlugAvailable (safeDomain, slug) {
            return $q(function (resolve) {
                const ref = $firebaseArray(firebaseDataService.shortlinks.child(safeDomain));
                ref.$loaded().then(function () {
                    slug = slug.replace(/[^\w|-]/g, ''); // remove pre-slash
                    resolve(!Boolean(ref.$getRecord(slug)));
                });
            });
        }
    }
    ;

})
();
