// eslint-disable-next-line func-names, func-style
(function () {
    'use strict';

    angular
        .module('app.auth')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'login',
                config: {
                    url: '/login',
                    title: 'Login',
                    cssClassnames: 'pattern-page',
                    parent: 'default',
                    views: {
                        'content@': {
                            component: 'login',
                        },
                    },
                },
            },
            // todo: implement subpages for each step of registration
            // todo: implement deeplinks that select a particular plan /register/:plan
            {
                state: 'register',
                config: {
                    url: '/register',
                    title: 'Register',
                    cssClassnames: 'pattern-page',
                    parent: 'default',
                    views: {
                        'content@': {
                            component: 'register',
                        },
                    },
                },
            },
        ];
    }
}());
