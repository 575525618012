(function () {
    'use strict';

    angular
        .module('app.presets')
        .component('presets', presets());

    function presets () {
        return {
            bindings: {
                user: '=',
            },
            controller: PresetsController,
            controllerAs: 'vm',
            templateUrl: 'app/presets/presets.html',
        };
    }

    PresetsController.$inject = [
        '$log',
        '$rootScope',
        'authService',
        'modalService',
        'presetService',
    ];

    /* @ngInject */
    function PresetsController ($log, $rootScope, authService, modalService, presetService) {
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        console.log(vm);
        vm.presets = presetService.getPresetsByUser(vm?.user?.uid);
        vm.presetTypes = [
            { label: 'Label', key: 'label' },
            { label: 'Slug', key: 'slug' },
            { label: 'UTM Campaign', key: 'utmCampaign' },
            { label: 'UTM Source', key: 'utmSource' },
            { label: 'UTM Medium', key: 'utmMedium' },
            { label: 'UTM Content', key: 'utmContent' },
            { label: 'UTM Term', key: 'utmTerm' }
        ];
        vm.openModal = modalService.create('app/presets/components/presetModal.html', 'PresetModalController');
        vm.setActive = setActive;
        vm.state = {};
        vm.state.activeType = 'label';
        vm.state.activeTypeLabel = 'Label';

        activate();

        // --------- //

        function activate () {
            authService.getProfile().then(function (res) {
                vm.profile = res;
                console.log(vm);
            }).catch(function (error) {
                alert('Error! ' + error);
            });

            // Listen for changes to the active domain in the ShortlinkFormModal
            $rootScope.$on('activeTypeChanged', function (event, type) {
                setActive(type);
                event.stopPropagation();
            });
        }

        function setActive (type) {
            vm.state.activeType = type.key;
            vm.state.activeTypeLabel = type.label;
        }
    }
}());
