(function () {
    'use strict';

    angular
        .module('app.pages')
        .component('disabled', disabled());

    function disabled () {
        return {
            bindings: {},
            templateUrl: 'app/pages/disabled.html',
        };
    }
}());
