(function () {
    'use strict';

    angular
        .module('app.welcome')
        .component('welcome', welcome());

    function welcome () {
        return {
            bindings: {},
            // controller: WelcomeController,
            // controllerAs: 'vm',
            templateUrl: 'app/welcome/welcome.html',
        };
    }

    // WelcomeController.$inject = [];

    // function WelcomeController () {
    //     const vm = this;
    // }
}());
