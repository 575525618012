(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('domainService', domainService);

    domainService.$inject = [
        '$log',
        '$q',
        '$firebaseArray',
        '$timeout',
        'firebaseDataService',
        'planService',
    ];

    function domainService ($log, $q, $firebaseArray, $timeout, firebaseDataService, planService) {
        //noinspection UnnecessaryLocalVariableJS
        const service = {
            addDomain,
            addPrimaryDomain,
            DomainSet,
            getDomains,
            hasAvailableDomain,
        };
        return service;

        ////////////////

        function DomainSet (baseDomain, protocol, shortlinkDomain, isAutoShortened) {
            if (!baseDomain) { throw Error('no baseDomain provided to DomainSet') }
            this.baseDomain = _encodeDots(baseDomain); // <string> comma-encoded
            this.protocol = protocol; // <string>
            this.shortlinkDomain = _encodeDots(shortlinkDomain) || 'goo,gl'; // <string> comma-encoded
            this.isAutoShortened = isAutoShortened || true; // <boolean>

            function _encodeDots (domain) {
                if (domain) {
                    return domain.replace(/\./g, ',');
                }
                return undefined;
            }
        }

        function addDomain (domainData) {
            var newDomain = new DomainSet(domainData.baseDomain, domainData.protocol);

            return getDomains().$add(newDomain)
                .catch(function (err) {
                    $log.error('Unexpected ERROR ==>', err);
                });
        }

        /**
         * @param domain - object with baseDomain and protocol properties
         * @returns {Promise}
         */
        function addPrimaryDomain (domain) {
            const { baseDomain, protocol } = domain;
            domain = new DomainSet(baseDomain, protocol);

            return firebaseDataService.userDomains.child('domain0').set(domain);
        }

        /**
         * Get all domains for logged in User
         * @returns {DomainSet[]}
         */
        function getDomains () {
            if (!domains) {
                var domains = $firebaseArray(firebaseDataService.userDomains);
            }

            return domains;
        }

        /**
         * Determine if the user should be allowed to add a new target domain to their account
         * @param state
         * @return {Promise<boolean>}
         */
        function hasAvailableDomain (state) {
            const _domains = getDomains();
            const timeoutForDomainCountPromise = $timeout(() => _domains.length);
            const getDomainLimitPromise = planService.getDomainLimit();

            // Waits for both promises to resolve, then processes the response values
            // then returns the end result to be received by the calling then().
            return $q.all([timeoutForDomainCountPromise, getDomainLimitPromise])
                .then((values) => {
                    const [domainsUsed, domainLimit] = values;

                    // Update state to ensure view has consistent data
                    state.domainsUsed = domainsUsed;
                    state.domainLimit = domainLimit;

                    return state.domainsUsed < state.domainLimit;
                });
        }
    }
})();
