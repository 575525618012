(function () {
    'use strict';

    angular
        .module('app.auth')
        .component('planForm', planForm());

    function planForm () {
        return {
            bindings: {
                selectedPlan: '=',
            },
            controller: PlanFormController,
            controllerAs: 'vm',
            templateUrl: 'app/auth/components/planForm.html',
        };
    }

    function PlanFormController () {
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        vm.isSelectedPlan = isSelectedPlan;
        vm.planClickHandler = planClickHandler;
        vm.state = {
            selectedPlan: 0 // todo: be able to set via $routeParams (when registering) & service (when updating)
        };
        vm.plans = [
            {
                code:'flat',
                icon: 'group_add',
                price: 25,
            },
            {
                code: 'solo',
                icon: 'person',
                price: 27
            },
            {
                code: 'business',
                icon: 'people',
                price: 47
            },
            {
                code: 'unlimited',
                icon: 'group_add',
                price: 97
            },
        ];
        vm.selectedPlan = vm.plans[vm.state.selectedPlan].code; // sets initial selected plan.

        function isSelectedPlan (index) {
            return vm.state.selectedPlan === index;
        }

        function planClickHandler (index) {
            vm.state.selectedPlan = index;
            vm.selectedPlan = vm.plans[index].code;
        }
    }
}());
