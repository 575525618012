/* global firebase:false */
(function () {
    'use strict';

    angular
        .module('app.core')
        .run(run);

    run.$inject = [
        'firebaseDataService',
    ];

    /* @ngInject */
    function run (firebaseDataService) {
        firebase.auth().onAuthStateChanged(function (authData) {
            // Runs whenever a route is loaded and there's an auth'd user
            if (authData) { firebaseDataService.addRefsForUser() }
        });
    }
}());
