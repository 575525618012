(function () {
    'use strict';

    angular
        .module('app.presets')
        .controller('PresetModalController', PresetModalController);

    PresetModalController.$inject = [
        '$log',
        '$mdDialog',
        '$rootScope',
        'alertService',
        'authService',
        'data',
        'presetService',
    ];

    /* @ngInject */
    function PresetModalController ($log, $mdDialog, $rootScope, alertService, authService, data, presetService) {
        const vm = this;
        vm.addPreset = addPreset;
        vm.alreadyExists = false;
        vm.cancel = () => $mdDialog.cancel();
        vm.changeActiveType = changeActiveType;
        vm.loading = false;
        vm.presetData = {
            type: "",
            text: ""
        };

        activate();

        // --------- //

        function activate () {
            vm.presetData.type = data;
        }

        function addPreset () {
            vm.loading = true;

            // todo: this should happen inside presetService.addPreset()
            if (vm.presetData.type !== 'label' && vm.presetData.type !== 'slug') {
                vm.presetData.text = vm.presetData.text.replace(/\s+/g, '+').toLowerCase();
            } else if (vm.presetData.type === 'slug') {
                vm.presetData.text = vm.presetData.text.replace(/\s+/g, '-').toLowerCase();
            }

            const result = presetService.addPreset(authService.getUID(), vm.presetData.type, vm.presetData.text);
            if (result) {
                vm.presetData.text = "";
                vm.alreadyExists = false;
                $mdDialog.hide('Preset added successfully.');
                alertService.new.generic('Your new preset was added');
            } else {
                vm.alreadyExists = true;
            }
            vm.loading = false;
        }

        function changeActiveType (typeKey) {
            const presetTypes = {
                label: { label: 'Label', key: 'label' },
                slug: { label: 'Slug', key: 'slug' },
                utmCampaign: { label: 'UTM Campaign', key: 'utmCampaign' },
                utmSource: { label: 'UTM Source', key: 'utmSource' },
                utmMedium: { label: 'UTM Medium', key: 'utmMedium' },
                utmContent: { label: 'UTM Content', key: 'utmContent' },
                utmTerm: { label: 'UTM Term', key: 'utmTerm' }
            };

            return $rootScope.$emit('activeTypeChanged', presetTypes[typeKey]);
        }
    }
}());
