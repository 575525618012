(function () {
    'use strict';

    angular
        .module('app.shortlinks')
        .controller('ShortlinkFormModalController', ShortlinkFormModalController);

    ShortlinkFormModalController.$inject = [
        '$log',
        '$filter',
        '$mdDialog',
        '$rootScope',
        'alertService',
        'data',
        'shortlinkService',
    ];

    function ShortlinkFormModalController ($log, $filter, $mdDialog, $rootScope, alertService, data, shortlinkService) {
        let originalLink;
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        vm.activeDomain = data.activeDomain;
        if (data.originalLink) {
            vm.originalLinkProvided = Boolean(data.originalLink);
            originalLink = data.originalLink;
        }
        vm.addShortlink = addShortlink;
        vm.alreadyExists = false;
        vm.cancel = () => $mdDialog.cancel();
        vm.changeActiveDomain = changeActiveDomain;
        vm.loading = false;
        vm.state = {};

        activate();

        ///////

        function activate () {
            vm.shortlinkDomains = shortlinkService.getShortlinkDomains();
            vm.shortlink = {};
            vm.state.alreadyExists = false;

            if (originalLink) vm.shortlink.originalUrl = originalLink;

            changeActiveDomain(vm.activeDomain);
            shortlinkService.getRandomSlug(vm.activeDomain).then(function (result) {
                vm.shortlink.slug = '/' + result;
            });
        }

        function changeActiveDomain (safeDomain, shouldRegenerateSlug) {
            if (safeDomain) {
                $log.log('running changeActiveDomain()');
                vm.activeDomain = safeDomain;
                if (shouldRegenerateSlug) {
                    shortlinkService.getRandomSlug(vm.activeDomain).then(function (result) {
                        vm.shortlink.slug = '/' + result;
                    });
                }
                shortlinkService.getShortlinksByDomain(safeDomain).then(function (res) {
                    vm.shortlinks = res;
                });
                $rootScope.$emit('activeDomainChanged', safeDomain);
            }
        }

        function addShortlink () {
            shortlinkService.addShortlinkForDomain(vm.activeDomain, vm.shortlink)
                .then(function () {
                    // convert vm.shortlink into link string and resolve that into .hide()
                    const ln = vm.shortlink;
                    const shortlinkString = 'http://' + $filter('decodeDots')(ln.domain) + ln.slug;
                    alertService.new.generic('Your new shortlink was added');
                    $mdDialog.hide(shortlinkString);
                })
                .catch(function (err) {
                    if (err === 'That shortlink already exists.') {
                        vm.state.alreadyExists = true;
                        // todo: have a shuffle button for the slug field
                    } else {
                        $log.error(Error(err));
                    }
                });
        }
    }

})();
