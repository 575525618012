(function () {
    'use strict';

    angular
        .module('app.pages')
        .run(appRun);

    appRun.$inject = [
        'authService',
        'routerHelper',
    ];
    /* @ngInject */
    function appRun (authService, routerHelper) {
        routerHelper.configureStates(getStates());

        function getStates () {
            return [
                {
                    state: 'disabled',
                    config: {
                        title: 'Account Disabled',
                        cssClassnames: 'pattern-page',
                        parent: 'default',
                        views: {
                            'content@': {
                                component: 'disabled',
                            }
                        },
                        resolve: {
                            user: authService.requireAuth,
                        },
                    }
                },
                {
                    state: 'missing-domain',
                    config: {
                        title: 'Missing Primary Domain',
                        cssClassnames: 'pattern-page',
                        parent: 'default',
                        views: {
                            'content@': {
                                component: 'missingDomain',
                            }
                        },
                        resolve: {
                            user: authService.requireAuth,
                        },
                    }
                },
                {
                    state: 'missing-shortlink-domain',
                    config: {
                        title: 'Missing Shortlink Domain',
                        cssClassnames: 'pattern-page',
                        parent: 'default',
                        views: {
                            'content@': {
                                component: 'missingShortlinkDomain',
                            }
                        },
                        resolve: {
                            user: authService.requireAuth,
                        },
                    }
                }
            ];
        }
    }

}());
