(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('decodeDots', decodeDots);

    function decodeDots () {
        return decodeDotsFilter;

        ////////////////

        function decodeDotsFilter (string) {
            if (!string) {
                return string;
            }

            return string.replace(/,/g, '.');
        }
    }
})();
