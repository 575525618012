(function () {
    'use strict';

    angular
        .module('app.library')
        .directive('thumbnailField', thumbnailField);

    function thumbnailField () {
        //noinspection UnnecessaryLocalVariableJS
        const directive = {
            templateUrl: 'app/library/components/thumbnailField.html',
            bindToController: true,
            controller: ThumbnailFieldController,
            controllerAs: 'vm',
            restrict: 'E',
            scope: {
                thumbnail: '=hmThumbnail',
            },
        };
        return directive;
    }

    ThumbnailFieldController.$inject = [
        '$log',
        '$scope',
    ];

    /* @ngInject */
    function ThumbnailFieldController ($log, $scope) {
        const vm = this;
        vm.onChange = onChange;
        vm.removeThumbnail = () => { vm.thumbnail = null; };
        vm.state = {};
        vm.state.ctaLabel = () => (vm.thumbnail) ? 'Replace Reference Image': 'Add Reference Image';

        activate();

        function activate () {
            if (!Modernizr.filereader) return document.getElementById('filereaderInput').remove(); // hide input & short-circuit if browser doesn't support filereader

            document.getElementById('filereaderFallback').classList.add('hidden');
        }

        function onChange (el) {
            const reader = new FileReader();
            const file = el.files[0];
            reader.onload = handleReaderLoad;
            reader.readAsDataURL(file);

            function handleReaderLoad (e) {
                $log.log('running handleReaderLoad()', e);
                const filePayload = e.target.result;
                $scope.$apply(() => { vm.thumbnail = filePayload;});
                // todo: resize pic before upload to keep size small (max: 1200px on longest side)
            }
        }
    }
})();
