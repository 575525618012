// eslint-disable-next-line func-names, func-style
(function () {
    'use strict';

    angular
        .module('app.layout')
        .component('ulmFooter', ulmFooter());

    function ulmFooter () {
        return {
            controller: FooterController,
            controllerAs: 'vm',
            templateUrl: 'app/layout/components/footer.html',
        };
    }

    FooterController.$inject = [
        '$log',
    ];

    /* @ngInject */
    function FooterController ($log) {}
}());
