(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('firebaseDataService', firebaseDataService);

    firebaseDataService.$inject = [
        '$log',
        '$firebaseAuth',
        'FIREBASE_CONFIG',
    ];

    function firebaseDataService ($log, $firebaseAuth, FIREBASE_CONFIG) {
        firebase.initializeApp(FIREBASE_CONFIG);
        const root = firebase.database().ref();

        //noinspection UnnecessaryLocalVariableJS
        let service = {
            root,
            addRefsForUser: addRefsForUser,
        };

        /**
         * When called, extends the service with the contents of userRefs
         */
        function addRefsForUser () {
            const uid = $firebaseAuth().$getAuth().uid;
            const userRefs = {
                limits: root.child('limits'),
                shortlinks: root.child('shortlinks'),
                users: root.child('users'),
                user: root.child(`users/${uid}`),
                userDomains: root.child(`users/${uid}/profile/domains`),
                userLinks: root.child(`users/${uid}/links`),
                userPresets: root.child(`users/${uid}/presets`),
                userProfile: root.child(`users/${uid}/profile`),
                userShortlinkDomains: root.child(`users/${uid}/shortlinkDomains`),
            };

            angular.extend(service, userRefs);
        }

        return service;
    }

})();
