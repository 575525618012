(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('planService', planService);

    planService.$inject = [
        '$log',
        '$firebaseObject',
        '$q',
        'firebaseDataService',
    ];

    function planService ($log, $firebaseObject, $q, firebaseDataService) {
        //noinspection UnnecessaryLocalVariableJS
        var service = {
            getClientType: getClientType,
            getDomainLimit: getDomainLimit,
            getPlan: getPlan,
            getShortlinkDomainLimit,
        };

        return service;

        ////////////////


        /**
         * @returns {Promise.<Object>} Promise that resolves res.$value = string of the user type (wso|saas)
         */
        function getClientType () {
            return $firebaseObject(firebaseDataService.userProfile.child('type')).$loaded();
        }

        /**
         * Get the total number of domains the logged in user has access to.
         * Total is calculated from addedDomains + baseLimit (for plan).
         * todo: known issue, doesn't update without page refresh (even if Firebase data changes)
         * @returns {Promise.<Int>} with callback argument {Int}
         */
        function getDomainLimit () {
            return _getAddedDomainCount().then(function (added_res) {
                return _getBaseLimit().then(function (base_res) {
                    return added_res.$value + base_res.$value;
                })
            });
        }

        /**
         * @returns {Promise.<Object>} Promise that resolves res.$value = string of the plan name
         */
        function getPlan () {
            return $firebaseObject(firebaseDataService.user.child('billing/plan')).$loaded();
        }

        /**
         * @returns {Promise.<Int>} Promise that resolves an Int = the total number of shortlink domains available for this account
         */
        function getShortlinkDomainLimit () {
            // get user type and return promise that resolves 0 if WSO and baseLimit + addonDomainCount if SaaS
            const planBasedLimitPromise = getClientType()
                .then((res) => {
                    $log.info(`Proceeding based on user type = ${res.$value}`);
                    if (res.$value === 'wso') {
                        return 0;
                    } else if (res.$value === 'saas') {
                        // since anyone on SaaS gets 1 shortlink domain for every regular domain
                        const baseLimitPromise = _getBaseLimit();
                        const addonDomainCountPromise = _getAddedDomainCount();

                        return $q.all([baseLimitPromise, addonDomainCountPromise])
                            .then((values) => {
                                const [baseLimit, addonDomainCount] = values;
                                return (baseLimit.$value + addonDomainCount.$value) || 1    ;
                            })
                    }
                });

            const addonShortlinkDomainCountPromise = _getAddedShortlinkDomainCount().then((res) => {return res.$value});

            return $q.all([planBasedLimitPromise, addonShortlinkDomainCountPromise])
                .then((values) => {
                    const [planBasedLimit, addonShortlinkDomainCount] = values;
                    $log.debug('Shortlink Domain Limit ==>', planBasedLimit + addonShortlinkDomainCount);
                    return planBasedLimit + addonShortlinkDomainCount;
                });
        }

        /**
         * @returns {Promise.<Object>} Promise that resolves res.$value = Int count of added domains
         */
        function _getAddedDomainCount () {
            return $firebaseObject(firebaseDataService.user.child('profile/added_domains')).$loaded();
        }

        /**
         * @returns {Promise.<Object>} Promise that resolves res.$value = Int count of added shortlink domains
         */
        function _getAddedShortlinkDomainCount () {
            return $firebaseObject(firebaseDataService.user.child('profile/added_shortlink_domains')).$loaded();
        }

        /**
         * @returns {Promise.<Object>} Promise that resolves res.$value = Int count domains included in the user's plan
         */
        function _getBaseLimit (plan) {
            if (!plan) {
                return getPlan().then(function (plan_res) {
                    return $firebaseObject(firebaseDataService.limits.child(`domains/${plan_res.$value}`)).$loaded();
                });
            }

            return $firebaseObject(firebaseDataService.limits.child(`domains/${plan}`)).$loaded();
        }
    }
})();
