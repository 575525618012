(function () {
    'use strict';

    angular
        .module('app.domains')
        .controller('DomainModalController', DomainModalController);

    DomainModalController.$inject = [
        '$log',
        '$mdDialog',
        '$mdToast',
        'domainService',
        'planService',
        'BASE_DOMAIN_PATTERN',
    ];

    /* @ngInject */
    function DomainModalController ($log, $mdDialog, $mdToast, domainService, planService, BASE_DOMAIN_PATTERN) {
        const vm = this;
        vm.domain = {};
        vm.addDomain = addDomain;
        vm.baseDomainPattern = BASE_DOMAIN_PATTERN;
        vm.cancel = () => $mdDialog.cancel();
        vm.state = {};
        vm.state.title = 'Add A Domain';

        activate();

        ////////////////

        function activate () {
            $log.info('Running', vm.state.title);
            vm.state.loading = true;

            // get userType
            planService.getClientType().then(function (res) {
                vm.state.clientType = res.$value;
            });

            domainService.hasAvailableDomain(vm.state)
                .then(function (hasAvailableDomain) {
                    vm.state.hasDomainsAvailable = hasAvailableDomain;
                    vm.state.loading = false;
                });
        }

        function addDomain () {
            domainService.addDomain(vm.domain).then(function () {
                $mdDialog.hide('Domain Added Successfully');
                $mdToast.show(
                    $mdToast.simple()
                        .textContent('Domain Added Successfully.')
                        .position('top right')
                        .toastClass('md-middle')
                        .capsule(true)
                );
            })
        }
    }
})();
