(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('confirmService', confirmService);

    confirmService.$inject = [
        '$mdDialog',
        '$mdToast',
    ];

    function confirmService ($mdDialog,$mdToast) {
        // noinspection UnnecessaryLocalVariableJS
        const service = {
            confirmThenToast,
        };
        return service;

        // --------- //

        /**
         *
         * @param e - event
         * @param {string} question - the question being asked of the user
         * @param action - method to be called if the user confirms
         * @param target - the parameter passed to `action` if the user confirms
         * @param {string} toastMsg - the message displayed after completing `action`
         */
        function confirmThenToast (e, question, action, target, toastMsg) {
            const confirmDialog = $mdDialog.confirm({
                title: 'Are you sure?',
                htmlContent: `<p>${question}</p><p><em>This action cannot be undone.</em></p>`,
                ok: 'Delete',
                cancel: 'Cancel',
                targetEvent: e,
            });

            $mdDialog.show(confirmDialog)
                .then(() => {
                    action(target);
                    $mdToast.show(
                        $mdToast.simple()
                            .textContent(toastMsg)
                            .position('top right')
                            .toastClass('md-middle')
                            .capsule(true)
                    );
                })
        }
    }
}());
