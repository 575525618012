(function () {
    'use strict';

    angular
        .module('app.domains')
        .component('domains', domains());

    function domains () {
        return {
            bindings: {},
            controller: DomainsController,
            controllerAs: 'vm',
            templateUrl: 'app/domains/domains.html',
        };
    }

    DomainsController.$inject = [
        '$log',
        '$scope',
        'domainService',
        'modalService',
        'planService',
        'shortlinkService',
    ];

    /* @ngInject */
    function DomainsController ($log, $scope, domainService, modalService, planService, shortlinkService) {
        const vm = this;
        vm.domains = domainService.getDomains();
        vm.shortlinkDomains = shortlinkService.getShortlinkDomains();
        vm.openModal = modalService.create('app/domains/components/domainModal.html', 'DomainModalController');
        vm.state = {};
        vm.state.hasAvailableShortlinkDomain = undefined;
        vm.state.loading = true;

        activate();

        ///////

        function activate () {
            // $watch vm.shortlinkDomains.length and then re-eval vm.hasAvailableShortlinkDomain
            $scope.$watch('vm.shortlinkDomains.length', () => {
                shortlinkService.hasAvailableShortlinkDomain(vm.shortlinkDomains, vm.state)
            });
            $scope.$watch('vm.state.shortlinkDomainLimit', () => {
                shortlinkService.hasAvailableShortlinkDomain(vm.shortlinkDomains, vm.state)
            });

            planService.getPlan().then(function (res) {
                vm.state.plan = res.$value;
            });

            planService.getDomainLimit().then(function (res) {
                vm.state.domainLimit = res;
            });

            planService.getClientType().then(function (res) {
                vm.state.clientType = res.$value;
            });

            planService.getShortlinkDomainLimit().then((shortlinkDomainLimit) => {
                vm.state.loading = false;
                vm.state.shortlinkDomainLimit = shortlinkDomainLimit;
            });
        }
    }
}());
