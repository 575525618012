(function () {
    'use strict';

    angular
        .module('app.domains')
        .component('hmShortlinkDomainsTable', hmShortlinkDomainsTable());

    function hmShortlinkDomainsTable () {
        return {
            bindings: {
                shortlinkDomains: '=',
                hasAvailableShortlinkDomain: '<',
            },
            controller: ShortlinkDomainsTableController,
            controllerAs: 'vm',
            templateUrl: 'app/domains/components/hmShortlinkDomainsTable.html',
        };
    }

    ShortlinkDomainsTableController.$inject = [
        '$log',
        '$q',
        '$scope',
        'confirmService',
        'modalService',
        'shortlinkService',
    ];

    /* @ngInject */
    function ShortlinkDomainsTableController ($log, $q, $scope, confirmService, modalService, shortlinkService) {
        const vm = this;
        vm.confirmThenDelete = confirmThenDelete;
        vm.openShortlinkDomainModal = modalService.create('app/domains/components/shortlinkDomainModal.html', 'ShortlinkDomainModalController');
        vm.removeShortlinkDomain = removeShortlinkDomain;
        vm.state = {};
        vm.state.loading = true;
        vm.verificationLinkHitCount = verificationLinkHitCount;

        activate();

        ///////

        function activate () {
            $log.info('Activated ShortlinkDomainsTableController');
            vm.state.loading = $q(resolve => { resolve() })
                .then(() => { vm.state.loading = false });

            $scope.$watch('vm.hasAvailableShortlinkDomain', (newValue) => {
                if (newValue) vm.state.loading = false;
            });
        }

        function confirmThenDelete (e, target) {
            const action = vm.removeShortlinkDomain;
            const question = 'Do you want to delete this shortlink domain?';
            const toastMsg = 'Shortlink domain deleted';

            confirmService.confirmThenToast(e, question, action, target, toastMsg);
        }

        /**
         * Used to check whether a given shortlink domain has been verified
         * @param safeDomain - comma-encoded shortlink domain
         * @returns {number} - number of visits registered to the verification link
         */
        function verificationLinkHitCount (safeDomain) {
            return shortlinkService.verifyCount(safeDomain);
        }

        function removeShortlinkDomain (shortlinkDomain) {
            vm.shortlinkDomains.$remove(shortlinkDomain);
        }
    }
})();
