(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('modalService', modalService);

    modalService.$inject = [
        '$log',
        '$mdDialog',
    ];

    function modalService ($log, $mdDialog) {
        //noinspection UnnecessaryLocalVariableJS
        var service = {
            create: create
        };
        return service;

        ////////////////

        /**
         *
         * @param templatePath - path to the template that should be rendered
         * @param controller - (optional) name of angular controller, will use simple controller (expose `vm`) if blank
         * @returns {open} - anonymous function that when called will open a modal base on the arguments provided when instantiated
         * @example vm.openModal = modalService.create('app/module/components/SomeModal.html','SomeModalController');
         */
        function create (templatePath, controller) {
            // $log.info(templatePath, controller);

            return function open (e, data) {
                fallbackController.$inject = ['$log', '$mdDialog', 'data'];
                $log.debug('data (optional)', data);

                return $mdDialog.show({
                    clickOutsideToClose: false,
                    controller: controller || fallbackController,
                    controllerAs: 'vm',
                    escapeToClose: false,
                    targetEvent: e,
                    templateUrl: templatePath,
                    locals: { data },
                });

                function fallbackController ($log, $mdDialog, data) {
                    const vm = this;
                    vm.cancel = () => $mdDialog.cancel();
                    vm.data = data || null;
                    vm.state = {};
                    vm.state.loading = false;
                }
            }
        }
    }
})();
