(function () {
    'use strict';

    angular
        .module('app.billing')
        .factory('billingService', billingService);

    billingService.$inject = [
        '$http',
        'BILLING_LAMBDA_URL',
        'LAMBDA_KEY',
    ];

    function billingService ($http, BILLING_LAMBDA_URL, LAMBDA_KEY) {
        //noinspection UnnecessaryLocalVariableJS
        const service = {
            createSubscription,
            getSubscriptionInfo,
        };

        return service;

        function createSubscription (payload) {
            // fixme: if the user validates their card and then abandons registration, their CC will still be charged
            // fixme: billing details should be verified *before* creating the user, but the subscription should be created *after*
            return $http.post(BILLING_LAMBDA_URL, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': LAMBDA_KEY
                }
            });
        }

        function getSubscriptionInfo () {
            // todo: get JSON of subscription data Lambda
        }
    }

})();
