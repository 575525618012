(function () {
    'use strict';

    angular
        .module('app.domains')
        .controller('ShortlinkDomainModalController', ShortlinkDomainModalController);

    ShortlinkDomainModalController.$inject = [
        '$filter',
        '$log',
        '$mdDialog',
        '$mdStepper',
        '$mdToast',
        '$state',
        '$timeout',
        'BASE_DOMAIN_PATTERN',
        'data',
        'planService',
        'shortlinkService',
    ];

    /* @ngInject */
    function ShortlinkDomainModalController ($filter, $log, $mdDialog, $mdStepper, $mdToast, $state, $timeout, BASE_DOMAIN_PATTERN, data, planService, shortlinkService) {
        const incompleteShortlinkDomainSetupData = (data) ? data.$value : undefined;
        const vm = this;
        vm.addShortlinkDomainToAccount = addShortlinkDomainToAccount;
        vm.baseDomainPattern = BASE_DOMAIN_PATTERN;
        vm.cancel = () => $mdDialog.cancel();
        vm.finish = finish;
        vm.finishConfigStep = finishConfigStep;
        vm.shortlinkDomains = shortlinkService.getShortlinkDomains();
        vm.state = {};
        vm.state.loading = true;
        vm.state.resumingIncompleteSetup = Boolean(incompleteShortlinkDomainSetupData);
        vm.state.title = 'Manage Shortlink Domains';
        vm.subdomainPattern = /^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/;

        activate();

        ///////

        function activate () {
            planService.getShortlinkDomainLimit()
                .then((shortlinkDomainLimit) => {
                    vm.state.shortlinkDomainLimit = shortlinkDomainLimit;
                    shortlinkService.hasAvailableShortlinkDomain(vm.shortlinkDomains, vm.state);
                        vm.stepper = vm.stepper || $mdStepper('shortlink-setup-stepper');
                        vm.newShortlinkDomain = {};
                        console.log('then()', {hasAvailableShortlinkDomain: vm.state.hasAvailableShortlinkDomain})
                        vm.state.loading = false;
                });

            $timeout(() => {
                console.log('timeout',{hasAvailableShortlinkDomain: vm.state.hasAvailableShortlinkDomain})
                if (vm.state.hasAvailableShortlinkDomain || incompleteShortlinkDomainSetupData) {
                    vm.stepper = $mdStepper('shortlink-setup-stepper');

                    if (incompleteShortlinkDomainSetupData) {
                        $log.info('You are trying to complete a shortlink domain setup');

                        // 1. copy necessary data into vm.newShortlinkDomain & vm.domain
                        vm.newShortlinkDomain = { style: 'subdomain' }; // assumes subdomain since root domains can't be used yet
                        vm.domain = _unpackSafeDomain(incompleteShortlinkDomainSetupData);

                        // 2. get the do ?????? so that the verification check works
                        vm.state.newDomainVerifyCount = shortlinkService.verifyCount(incompleteShortlinkDomainSetupData);

                        // 3. skip to appropriate step of the wizard (vm.stepper)
                        vm.stepper.goto(3);
                    } else {
                        vm.newShortlinkDomain = {};
                    }
                }
            });
        }

        function addShortlinkDomainToAccount (domainData) {
            // create newShortlinkDomain => X
            const newShortlinkDomain = $filter('encodeDots')(`${domainData.subdomain}.${domainData.baseDomain}`);

            // add shortlinkDomain
            shortlinkService.addShortlinkDomain(newShortlinkDomain)
                .then(() => {
                    vm.state.newDomainVerifyCount = shortlinkService.verifyCount(newShortlinkDomain);
                    vm.stepper.next();
                })
                .catch((err) => {
                    $log.error('shortlinkService.addShortlinkDomain err ==>', err);
                    if (err === 'duplicate') {
                        vm.stepper.error('Shortlink Domain used by another account.');
                    }
                });
        }

        function finish () {
            $mdDialog.hide('Success!');
            $state.go('domains'); // fixme: when on the domains page, this takes you to the library...
            $mdToast.show(
                $mdToast.simple()
                    .textContent('New shortlink domain added successfully!')
                    .position('top right')
                    .toastClass('md-middle')
                    .capsule(true)
            );
        }

        function finishConfigStep () {
            $timeout(() => { vm.stepper.next() });
        }

        function _unpackSafeDomain (safeDomain) {
            const splitByComma = safeDomain.split(',');
            const subdomain = splitByComma.shift();
            const baseDomain = splitByComma.join('.');

            return {
                subdomain,
                baseDomain,
            };
        }

    }
})();
