(function () {
    'use strict';

    angular
        .module('app.shortlinks')
        .component('shortlinksTable', shortlinksTable());

    function shortlinksTable () {
        return {
            bindings: {
                shortlinks: '=',
                activeDomain: '=',
            },
            controller: ShortlinkTableController,
            controllerAs: 'vm',
            templateUrl: 'app/shortlinks/components/shortlinksTable.html',
        };
    }

    ShortlinkTableController.$inject = [
        '$log',
        '$filter',
        '$mdToast',
        '$q',
        'clipboard',
        'confirmService',
    ];

    /* @ngInject */
    function ShortlinkTableController ($log, $filter, $mdToast, $q, clipboard, confirmService) {
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        vm.buildShortlink = function (domain, slug) { return $filter('decodeDots')(domain) + '/' + slug };
        vm.confirmThenDelete = confirmThenDelete;
        vm.copy = copy;
        vm.removeShortlink = function (link) { vm.shortlinks.$remove(link) };
        vm.state = {
            filteredItems: [],
            mdLimit: 10,
            mdLimitOptions: [10, 50, 100],
            mdPage: 1,
            mdOrder: '-dateAdded',
            search: '',
        };

        activate();

        ///////

        function activate () {
            vm.state.loading = $q(resolve => { resolve() })
                .then(() => { vm.state.loading = false });
        }

        function confirmThenDelete (e, target) {
            const action = vm.removeShortlink;
            const question = 'Do you want to permanently delete this shortlink?';
            const toastMsg = 'Shortlink deleted';

            confirmService.confirmThenToast(e, question, action, target, toastMsg);
        }

        function copy (textToCopy) {
            clipboard.copyText(textToCopy);
            $mdToast.show(
                $mdToast.simple()
                    .textContent('Copied to clipboard')
                    .position('top right')
                    .toastClass('md-middle')
                    .capsule(true)
            );
        }
    }
}());
