/* Help configure the state-base ui.router */
// eslint-disable-next-line func-names, func-style
(function () {
    'use strict';

    angular
        .module('blocks.router')
        .provider('routerHelper', routerHelperProvider);

    routerHelperProvider.$inject = [
        '$locationProvider',
        '$stateProvider',
        '$urlRouterProvider',
        '$windowProvider',
    ];

    /* @ngInject */
    function routerHelperProvider ($locationProvider, $stateProvider, $urlRouterProvider, $windowProvider) {
        const $window = $windowProvider.$get();

        const config = {
            docTitle: undefined,
            resolveAlways: {},
        };

        if (!($window.history && $window.history.pushState)) {
            // eslint-disable-next-line no-param-reassign
            $window.location.hash = '/';
        }

        $locationProvider.html5Mode(false);

        this.configure = (cfg) => { angular.extend(config, cfg); };

        // noinspection JSUnusedGlobalSymbols
        this.$get = RouterHelper;
        RouterHelper.$inject = [
            '$location',
            '$rootScope',
            '$state',
            '$log',
            '$transitions',
        ];

        /* @ngInject */
        function RouterHelper ($location, $rootScope, $state, $log, $transitions) {
            let handlingStateChangeError = false;
            let hasOtherwise = false;
            const stateCounts = {
                errors: 0,
                changes: 0,
            };

            const service = {
                configureStates,
                getStates,
                stateCounts,
            };

            init();

            return service;

            // --------- //

            function configureStates (states, otherwisePath) {
                states.forEach((state) => {
                    // eslint-disable-next-line no-param-reassign
                    state.config.resolve =
                        angular.extend(state.config.resolve || {}, config.resolveAlways);
                    $stateProvider.state(state.state, state.config);
                });
                if (otherwisePath && !hasOtherwise) {
                    hasOtherwise = true;
                    $urlRouterProvider.otherwise(otherwisePath);
                }
            }

            function handleRoutingErrors () {
                // Route cancellation:
                // On routing error, go to the dashboard.
                // Provide an exit clause if it tries to do it twice.
                $transitions.onError({}, onTransitionError);

                function onTransitionError ($transition$) {
                    const toState = $transition$.$to();
                    const error = $transition$.error();

                    if (handlingStateChangeError) {
                        return;
                    }
                    stateCounts.errors += 1;
                    handlingStateChangeError = true;
                    if (error.detail === 'AUTH_REQUIRED') {
                        $log.info('You must be signed in to view that page.');
                        $state.go('login');
                    } else if (error.detail === 'ACCOUNT_DISABLED') {
                        $log.info('Your account appears to be disabled.');
                        $state.go('disabled');
                    } else if (error.detail === 'MISSING_DOMAIN') {
                        $log.info('Your account is missing a primary domain.');
                        $state.go('missing-domain');
                    } else if (error.detail === 'MISSING_SHORTLINK_DOMAIN') {
                        $log.info('Your account is missing a shortlink domain.');
                        $state.go('missing-shortlink-domain');
                    } else {
                        const destination = (toState &&
                            (toState.title || toState.name || toState.loadedTemplateUrl)) ||
                            'unknown target';
                        const msg = `Error routing to ${destination}. ${error.data || ''}${error.statusText || ''}: ${error.status || ''}`;
                        $log.error(msg, [toState]);
                        $location.path('/');
                    }
                }
            }

            function init () {
                handleRoutingErrors();
                updateDocTitle();
            }

            function getStates () { return $state.get(); }

            function updateDocTitle () {
                $transitions.onSuccess({}, onTransitionSuccess);

                function onTransitionSuccess ($transition$) {
                    stateCounts.changes += 1;
                    handlingStateChangeError = false;
                    const stateTitle = $transition$.$to().title;
                    // eslint-disable-next-line no-param-reassign
                    $rootScope.title = (stateTitle) ? `${stateTitle} | ${config.docTitle}` : 'stateTitle'; // data bind to <title>
                }
            }
        }
    }
}());
