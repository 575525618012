// eslint-disable-next-line func-names, func-style
(function () {
    'use strict';

    angular
        .module('app.welcome')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'welcome',
                config: {
                    url: '/welcome',
                    title: 'Welcome',
                    cssClassnames: 'pattern-page',
                    parent: 'private',
                    views: {
                        'content@': {
                            component: 'welcome',
                        },
                    },
                }
            },
        ];
    }
}());
