(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('linkService', linkService);

    linkService.$inject = [
        '$firebaseArray',
        '$log',
        'firebaseDataService',
        'alertService',
        'authService',
        'presetService',
    ];

    function linkService ($firebaseArray, $log, firebaseDataService, alertService, authService, presetService) {

        var links = null;

        //noinspection UnnecessaryLocalVariableJS
        var service = {
            addNewLink,
            autoShortlinkIfEnabled,
            buildUTMLink: buildUTMLink,
            cleanLink: cleanLink,
            getLinksByUser: getLinksByUser,
            isDuplicate: isDuplicate,
            Link: Link,
            reset: reset,
        };

        return service;

        ////////////

        function Link () {
            this.creator = '';
            //noinspection JSUnusedGlobalSymbols
            this.dateAdded = '';
            this.desc = '';
            this.domain = '';
            this.label = '';
            this.shortlink = undefined;
            this.slug = '';
            this.utmCampaign = '';
            this.utmContent = '';
            this.utmMedium = '';
            this.utmSource = '';
            this.utmTerm = '';
        }

        /**
         *
         * @param domains
         * @param links
         * @param link
         * @param shortlinkService
         * @returns {Promise}
         */
        function addNewLink (domains, links, link, shortlinkService) {
            link = this.cleanLink(link); // clean link
            link.creator = authService.getEmail(); // add link creator
            link.dateAdded = Math.floor(Date.now() / 1000); // set timestamp

            return this.autoShortlinkIfEnabled(link, domains, shortlinkService) // shortlink if enabled
                .then(finishAddLink); // .catch() // todo: error handling

            function finishAddLink () { // passed into .then()
                links.$add(link)
                    .then(() => { alertService.new.generic('Your new link was added'); })
                    .catch(error => { $log.error('Error! ' + error); });

                presetService.addPresetsFromLink(link, authService.getUID());
            }
        }

        /**
         * Creates the right kind of shortlink & adds it to link.shortlink IF the users settings are set to autolink
         * nb: shortlinkService needs to be param b/c otherwise the inclusion is circular
         * @param link {Link} - the new link
         * @param domains {FirebaseArray} - FirebaseArray of domains
         * @param shortlinkService
         * @returns {Promise}
         */
        function autoShortlinkIfEnabled (link, domains, shortlinkService) {
            return new Promise((resolve, reject) => {
                if (shortlinkService.shouldGenerateShortlinks(link, domains)) {
                    const shortlinkDomain = shortlinkService.getShortlinkDomainForLink(link, domains); // 1. get the shortlink domain for this link
                    const longLink = this.buildUTMLink(link, true); // 2. construct the full UTM link
                    if (shortlinkDomain === 'goo,gl') {
                        return shortlinkService.createTheGooglShortlink(longLink)
                            .then((res) => {
                                link.shortlink = res.data.id;
                                resolve('generic shortlink generated');
                            });
                    } else {
                        return shortlinkService.addGeneratedShortlink(longLink, shortlinkDomain)
                            .then((shortlinkString) => {
                                link.shortlink = shortlinkString;
                                resolve('branded shortlink generated');
                            });
                    }
                } else {
                    resolve('no shortlink generated');
                }
            });
        }

        function buildUTMLink (link, urlOnly) {
            // check if original link already has its own params
            const hasOwnParams = (link.slug.indexOf('?') !== -1);
            const questionMarkIfNeeded = (hasOwnParams) ? '&' : '?';

            // prevent double forward slash
            const endsInSlash = (link.slug.substr(-1) === '/');
            const endSlashIfNeeded = (endsInSlash || hasOwnParams) ? '' : '/';

            // prevent no slash between domain and slug
            const slugStartsWithSlash = link.slug.substr(0, 1) === '/';
            const domainEndsWithSlash = link.domain.substr(-1) === '/';
            const hasSlug = Boolean(link.slug.length);
            const midSlashIfNeeded = !(slugStartsWithSlash || domainEndsWithSlash) && hasSlug ? '/' : '';

            urlOnly = urlOnly || false;
            link.slug = link.slug || '';
            let ln = `${link.domain + midSlashIfNeeded + link.slug + endSlashIfNeeded + questionMarkIfNeeded}utm_source=${link.utmSource}&utm_medium=${link.utmMedium}&utm_campaign=${link.utmCampaign}`;
            ln += (link.utmTerm) ? `&utm_term=${link.utmTerm}` : '';
            ln += (link.utmContent) ? `&utm_content=${link.utmContent}` : '';

            if (urlOnly) {
                return ln;
            } else {
                return `<a href="${ln}" target="_blank">${ln}</a>`;
            }
        }

        function getLinksByUser () {
            if (!links) {
                links = $firebaseArray(firebaseDataService.userLinks);
            }
            return links;
        }

        function reset () {
            if (links) {
                links.$destroy();
                links = null;
            }
        }

        /**
         * Determine if link with identical UTM content already exists
         * @param {Link} newLink - the link you want to check is unique
         * @param {Link[]} links - the current array of links
         * @returns {boolean} result - true if the link is a duplicate (not unique)
         */
        function isDuplicate (newLink, links) {
            // start with the ones least likely to match
            /*var fields = [
             'utmContent',
             'utmCampaign',
             'utmMedium',
             'utmSource',
             'domain',
             'slug',
             'utmTerm'
             ];*/

            // check if *any* link is the same based on the specified fields (then stop)
            return links.some(function (lnk) {
                // todo: conduct this check based on var fields
                return newLink['utmContent'] === lnk['utmContent'] &&
                    newLink['utmCampaign'] === lnk['utmCampaign'] &&
                    newLink['utmMedium'] === lnk['utmMedium'] &&
                    newLink['utmSource'] === lnk['utmSource'] &&
                    newLink['domain'] === lnk['domain'] &&
                    newLink['slug'] === lnk['slug'] &&
                    newLink['utmTerm'] === lnk['utmTerm'];
            });

            /*if (finalResult) {
             console.log('already exists 👎🏼');
             } else {
             console.log('is unique 👍🏼');
             }*/
        }


        /**
         * 1. Lowercase key values in the link to ensure consistency
         * 2. Replace spaces in slug with '-'
         * 3. Replace spaces in utm values with '+'
         * @param link
         * @returns {*} cleaned link
         */
        function cleanLink (link) {
            var cleanLink = link;

            cleanLink.slug = angular.isString(link.slug) ? link.slug.replace(/\s+/g, '-') : link.slug;
            cleanLink.utmCampaign = angular.isString(link.utmCampaign) ? link.utmCampaign.replace(/\s+/g, '+').toLowerCase() : link.utmCampaign;
            cleanLink.utmContent = angular.isString(link.utmContent) ? link.utmContent.replace(/\s+/g, '+').toLowerCase() : link.utmContent;
            cleanLink.utmMedium = angular.isString(link.utmMedium) ? link.utmMedium.replace(/\s+/g, '+').toLowerCase() : link.utmMedium;
            cleanLink.utmSource = angular.isString(link.utmSource) ? link.utmSource.replace(/\s+/g, '+').toLowerCase() : link.utmSource;
            cleanLink.utmTerm = angular.isString(link.utmTerm) ? link.utmTerm.replace(/\s+/g, '+').toLowerCase() : link.utmTerm;

            return cleanLink;
        }

    }

})();
