(function () {
    'use strict';

    angular
        .module('app.shortlinks')
        .component('shortlinks', shortlinks());

    function shortlinks () {
        return {
            bindings: {},
            controller: ShortlinksController,
            controllerAs: 'vm',
            templateUrl: 'app/shortlinks/shortlinks.html',
        };
    }

    ShortlinksController.$inject = [
        '$log',
        '$rootScope',
        'modalService',
        'authService',
        'shortlinkService',
    ];

    /* @ngInject */
    function ShortlinksController ($log, $rootScope, modalService, authService, shortlinkService) {
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        vm.changeActiveDomain = changeActiveDomain;
        vm.shortlinkDomains = shortlinkService.getShortlinkDomains();
        vm.openModal = modalService.create('app/shortlinks/components/shortlinkFormModal.html', 'ShortlinkFormModalController');
        vm.state = {};

        activate();

        // --------- //

        function activate () {
            authService.getProfile()
                .then(function (res) {
                    vm.profile = res;
                })
                .catch(function (error) {
                    alert('Error! ' + error);
                });

            initActiveDomain();

            // Listen for changes to the active domain in the ShortlinkFormModal
            $rootScope.$on('activeDomainChanged', function (event, newDomain) {
                changeActiveDomain(newDomain);
                event.stopPropagation();
            });
        }

        function initActiveDomain () {
            shortlinkService.getShortlinkDomains().$loaded() // 1. get shortlink domains
                .then((shortlinkDomains) => {
                    if (shortlinkDomains.length === 0) {
                        // user hasn't setup a shortlink domain yet
                        vm.openShortlinkDomainRequiredModal = modalService.create('app/shortlinks/components/shortlinkDomainRequiredModal.html');
                        vm.openShortlinkDomainRequiredModal();
                    } else {
                        changeActiveDomain(shortlinkDomains[0].$value); // 2. activate the first one
                    }
                });
        }

        function changeActiveDomain (newDomain) {
            // set state loading
            shortlinkService.getShortlinksByDomain(newDomain).then(function (res) {
                vm.shortlinks = res;
            }).catch(function (error) {
                alert('Error! ' + error);
            });
            vm.state.activeDomain = newDomain;
        }
    }
}());
