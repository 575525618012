(function () {
    'use strict';

    angular
        .module('app.pages')
        .component('missingDomain', missingDomain());

    function missingDomain () {
        return {
            bindings: {},
            controller: MissingDomainController,
            controllerAs: 'vm',
            templateUrl: 'app/pages/missingDomain.html',
        };
    }

    MissingDomainController.$inject = [
        '$log',
        '$state',
        'domainService',
    ];

    /* @ngInject */
    function MissingDomainController ($log, $state, domainService) {
        const vm = this;
        vm.addDomain = addDomain;
        vm.domain = {};
        vm.state = {};
        vm.submitButtonText = () => (vm.state.loading) ? 'Loading...' : 'Activate My Account';

        activate();

        ///////

        function activate () {
            vm.state.loading = false;
        }

        function addDomain () {
            vm.state.loading = true;
            domainService.addPrimaryDomain(vm.domain)
                .then(() => {
                    $log.info('Primary domain added successfully');
                    $state.go('library');
                })
                .catch((err) => {
                    $log.error('Add Primary Domain Error', err);
                    // todo: display generic error to user
                    vm.state.loading = false;
                });
        }
    }
}());
