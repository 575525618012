(function () {
    'use strict';

    angular
        .module('app.pages')
        .component('missingShortlinkDomain', missingShortlinkDomain());

    function missingShortlinkDomain () {
        return {
            bindings: {},
            controller: MissingShortlinkDomainController,
            controllerAs: 'vm',
            templateUrl: 'app/pages/missingShortlinkDomain.html',
        };
    }

    MissingShortlinkDomainController.$inject = [
        '$q',
        'modalService'
    ];

    /* @ngInject */
    function MissingShortlinkDomainController ($q, modalService) {
        const vm = this;
        vm.openShortlinkDomainModal = modalService.create('app/domains/components/shortlinkDomainModal.html', 'ShortlinkDomainModalController');
        vm.state = {};
        vm.state.loading = true;

        // FIXME: check what happens if you abandon

        activate();

        ///////

        function activate () {
            vm.state.loading = $q(resolve => { resolve() })
                .then(() => { vm.state.loading = false });
        }
    }
}());
