/* eslint angular/file-name:0, angular/service-name:0 */
(function () {
    'use strict';

    angular
        .module('app.core')
        .constant('BILLING_LAMBDA_URL', getConstant('BILLING_LAMBDA_URL'))
        .constant('BASE_DOMAIN_PATTERN', /^([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(\.[a-zA-Z]{2,}){1,3}$/)
        .constant('FIREBASE_CONFIG', getConstant('FIREBASE_CONFIG'))
        .constant('FIREBASE_URL', getConstant('FIREBASE_URL'))
        .constant('LAMBDA_KEY', getConstant('LAMBDA_KEY'))
        .constant('POST_REGISTRATION_LAMBDA_URL', getConstant('POST_REGISTRATION_LAMBDA_URL'))
        .constant('STRIPE_PLAN_ID', getConstant('STRIPE_PLAN_ID'))
        .constant('STRIPE_PUBLISHABLE_KEY', getConstant('STRIPE_PUBLISHABLE_KEY'))
        .constant('PROTECTED_PATHS', ['/library']);

    function getConstant (key) {
        var constants = {
            'prod': {
                'BILLING_LAMBDA_URL': 'https://90n9cjjmgb.execute-api.us-west-2.amazonaws.com/stripe/prod',
                'FIREBASE_CONFIG': {
                    apiKey: "AIzaSyAapzPcD8N8aulmiNJNAEGVyyk-wDunm_k",
                    authDomain: "utm-link-manager.firebaseapp.com",
                    databaseURL: "https://utm-link-manager.firebaseio.com",
                    projectId: "utm-link-manager",
                    storageBucket: "utm-link-manager.appspot.com",
                    messagingSenderId: "94631414565"
                },
                'FIREBASE_URL': 'https://utm-link-manager.firebaseio.com/',
                'LAMBDA_KEY': 'F2D4zOBfEj7DV2C8Y5Kxb6PbwdQ1yAZj2VQ0lb8G',
                'POST_REGISTRATION_LAMBDA_URL': 'https://90n9cjjmgb.execute-api.us-west-2.amazonaws.com/register/prod',
                'STRIPE_PLAN_ID':'price_1KOva0DtsHKKfroo3jMuNCZm',
                'STRIPE_PUBLISHABLE_KEY': 'pk_live_51KGAXbDtsHKKfrooyUvVCVrYBM6spGnrlPKNfUOEpT9jL9YPhxJ3boimpf2GzF2eC23ZryMaNzK06xj01RWaxpEd00HafWMXCW'
            },
            'staging': {
                'BILLING_LAMBDA_URL': 'https://90n9cjjmgb.execute-api.us-west-2.amazonaws.com/stripe/staging',
                // FIXME: seems to get stuck during registration -- looks like the stripeToken isn't getting found...
                //  also, error message text isn't making it into the registration form (and the submit button stays disabled)
                'FIREBASE_CONFIG': {
                    apiKey: "AIzaSyDvbHuxrZmFjnTzkMmTtDoCU0CT8AAsJXw",
                    authDomain: "ulm-staging.firebaseapp.com",
                    databaseURL: "https://ulm-staging.firebaseio.com",
                    projectId: "ulm-staging",
                    storageBucket: "ulm-staging.appspot.com",
                    messagingSenderId: "134942867927"
                },
                'FIREBASE_URL': 'https://ulm-staging.firebaseio.com/',
                'LAMBDA_KEY': 'F2D4zOBfEj7DV2C8Y5Kxb6PbwdQ1yAZj2VQ0lb8G',
                'POST_REGISTRATION_LAMBDA_URL': 'https://90n9cjjmgb.execute-api.us-west-2.amazonaws.com/register/staging',
                'STRIPE_PLAN_ID':'price_1KOz0cDtsHKKfrooCRKBrb33',
                'STRIPE_PUBLISHABLE_KEY': 'pk_test_51KGAXbDtsHKKfroom8aRMfaeKWI7cGIDYmgkHMIHJH1L3dZnVVGUlUhxTBPhON0DVioXfnVcOseBVvirEIvaraJ600doIcRJCd'
            }
        };

        var env = window.location.hostname === 'app.utmlinkmanager.com' ? 'prod' : 'staging';

        return constants[env][key];
    }
})();
