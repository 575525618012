(function () {
    'use strict';

    angular
        .module('app.presets')
        .run(appRun);

    appRun.$inject = ['routerHelper'];
    /* @ngInject */
    function appRun (routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates () {
        return [
            {
                state: 'presets',
                config: {
                    url: '/presets',
                    title: 'Manage Presets',
                    parent: 'private',
                    views: {
                        'content@': {
                            component: 'presets',
                        }
                    }
                }
            }
        ];
    }
}());
