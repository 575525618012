(function () {
    'use strict';

    angular
        .module('app.services')
        .factory('presetService', presetService);

    presetService.$inject = [
        '$firebaseArray',
        '$log',
        'authService',
        'firebaseDataService'
    ];

    function presetService ($firebaseArray, $log, authService, firebaseDataService) {

        let presets = null;

        //noinspection UnnecessaryLocalVariableJS
        const service = {
            Preset: Preset,
            getPresetsByUser: getPresetsByUser,
            addPreset: addPreset,
            addPresetsFromLink: addPresetsFromLink
        };

        return service;

        ////////////

        function Preset (label) {
            return {
                    dateAdded: Math.floor(Date.now() / 1000),
                creator: authService.getEmail(),
                label: label
            }
        }

        function getPresetsByUser (uid) {
            if (!presets) {
                presets = {};
                presets.label = $firebaseArray(firebaseDataService.userPresets.child('label'));
                presets.slug = $firebaseArray(firebaseDataService.userPresets.child('slug'));
                presets.utmSource = $firebaseArray(firebaseDataService.userPresets.child('utmSource'));
                presets.utmCampaign = $firebaseArray(firebaseDataService.userPresets.child('utmCampaign'));
                presets.utmMedium = $firebaseArray(firebaseDataService.userPresets.child('utmMedium'));
                presets.utmContent = $firebaseArray(firebaseDataService.userPresets.child('utmContent'));
                presets.utmTerm = $firebaseArray(firebaseDataService.userPresets.child('utmTerm'));
            }
            return presets;
        }

        function addPreset (uid, fieldName, label) {
            var ref = firebaseDataService.userPresets.child(fieldName);
            var result;
            ref.orderByChild('label')
                .equalTo(label)
                .once('value', function (snapshot) {
                    var val = snapshot.val();
                    if (!val) {
                        // fieldName: fieldValue - preset doesn't exist yet, let's create it
                        var newPreset = Preset(label);
                        $firebaseArray(firebaseDataService.userPresets.child(fieldName)).$add(newPreset);
                        result = true;
                    } else {
                        result = false;
                    }
                });
            return result;
        }

        function addPresetsFromLink (link, uid) {
            activate();

            ////////

            function activate () {
                processField('label');
                processField('slug');
                processField('utmCampaign');
                processField('utmSource');
                processField('utmMedium');
                processField('utmContent');
                processField('utmTerm');
            }

            function processField (fieldName) {
                var fieldValue = link[fieldName];

                // check field valid and needs processing
                if (angular.isString(fieldValue) && fieldValue.length !== 0) {
                    var ref = firebaseDataService.userPresets.child(fieldName);
                    ref.orderByChild('label')
                        .equalTo(fieldValue)
                        .once('value', function (snapshot) {
                            var val = snapshot.val();
                            if (!val) {
                                // fieldName: fieldValue - preset doesn't exist yet, let's create it
                                var newPreset = Preset(fieldValue);
                                $firebaseArray(firebaseDataService.userPresets.child(fieldName)).$add(newPreset);
                            } // else { 'fieldName': 'fieldValue' - preset already exists }
                        });
                }
            }
        }
    }

})();
