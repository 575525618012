(function () {
    'use strict';

    angular
        .module('app.presets')
        .component('presetsTable', presetsTable());

    function presetsTable () {
        return {
            bindings: {
                presets: '=',
                activeType: '=',
            },
            controller: PresetsControllerController,
            controllerAs: 'vm',
            templateUrl: 'app/presets/components/presetsTable.html',
        };
    }

    PresetsControllerController.$inject = [
        '$log',
        '$q',
        'confirmService',
    ];

    /* @ngInject */
    function PresetsControllerController ($log, $q, confirmService) {
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        vm.removePreset = function (preset) { vm.presets[vm.activeType].$remove(preset); };
        vm.confirmThenDelete = confirmThenDelete;
        vm.state = {
            filteredItems: [],
            mdLimit: 10,
            mdLimitOptions: [10,50,100],
            mdPage: 1,
            mdOrder: '-dateAdded',
            search: '',
        };

        activate();

        ///////

        function activate () {
            vm.state.loading = $q(resolve => { resolve() })
                .then(() => { vm.state.loading = false });
        }

        function confirmThenDelete (e, target) {
            const action = vm.removePreset;
            const question = 'Do you want to permanently delete this preset?';
            const toastMsg = 'Preset deleted';

            confirmService.confirmThenToast(e, question, action, target, toastMsg);
        }
    }
}());
