(function () {
    'use strict';

    angular
        .module('app.library')
        .directive('hmLinkTable', hmLinkTable);

    function hmLinkTable () {
        return {
            templateUrl: 'app/library/components/hmLinkTable.html',
            restrict: 'E',
            controller: LinkTableController,
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                links: '=',
                openModal: '='
            }
        };
    }

    LinkTableController.$inject = [
        '$log',
        '$mdToast',
        '$q',
        'clipboard',
        'confirmService',
        'domainService',
        'linkService',
        'modalService',
        'shortlinkService',
    ];

    function LinkTableController ($log, $mdToast, $q, clipboard, confirmService, domainService, linkService, modalService, shortlinkService) {
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        vm.buildUTMLink = linkService.buildUTMLink;
        vm.copy = copy;
        vm.confirmThenDelete = confirmThenDelete;
        vm.createShortlink = (e, link) => { shortlinkService.addShortlinkForLink(e, link, vm.state.domains, vm.links, vm.openShortlinkModal) };
        vm.openThumbnailModal = modalService.create('app/library/components/thumbnailModal.html');
        vm.removeLink = function (link) { vm.links.$remove(link) };
        vm.openShortlinkModal = modalService.create('app/shortlinks/components/shortlinkFormModal.html', 'ShortlinkFormModalController');
        vm.state = {
            filteredItems: [],
            mdLimit: 10,
            mdLimitOptions: [10, 50, 100],
            mdPage: 1,
            mdOrder: '-dateAdded',
            search: '',
        };

        activate();

        ///////

        function activate () {
            vm.state.loading = $q(resolve => { resolve() })
                .then(() => { vm.state.loading = false });
            vm.state.domains = domainService.getDomains();
        }

        function confirmThenDelete (e, target) {
            const action = vm.removeLink;
            const question = 'Do you want to permanently delete this UTM link?';
            const toastMsg = 'UTM Link deleted';

            confirmService.confirmThenToast(e, question, action, target, toastMsg);
        }

        function copy (textToCopy) {
            clipboard.copyText(textToCopy);
            $mdToast.show(
                $mdToast.simple()
                    .textContent('Copied to clipboard')
                    .position('top right')
                    .toastClass('md-middle')
                    .capsule(true)
            );
        }
    }
})();
