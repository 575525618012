(function () {
    'use strict';

    angular
        .module('app.auth')
        .component('register', register());

    function register () {
        return {
            bindings: {
                error: '=',
                loading: '=',
            },
            controller: RegisterController,
            controllerAs: 'vm',
            templateUrl: 'app/auth/components/register.html',
        };
    }

    // END temp

    RegisterController.$inject = [
        '$timeout',
        '$log',
        '$window',
        'alertService',
        'authService',
        'billingService',
        'STRIPE_PLAN_ID'
    ];

    /* @ngInject */
    function RegisterController ($timeout, $log, $window, alertService, authService, billingService, STRIPE_PLAN_ID) {
        /**
         * @name vm
         * @type {object}
         */
        const vm = this;
        vm.accountInfo = vm.accountInfo || {};
        vm.billingErrors = [];
        vm.businessInfo = vm.businessInfo || {};
        vm.cannotSubmitForm = cannotSubmitForm;
        vm.createBillingToken = createBillingToken;
        vm.errors = [];
        vm.evalSendValueToBilling = evalSendValueToBilling;
        vm.evalUseAccountAsBilling = evalUseAccountAsBilling;
        vm.payload = {};
        vm.state = {};
        vm.stopUsingAccountAsBilling = stopUsingAccountAsBilling;
        vm.submitCallback = function () { vm.loading = true; };
        vm.userInfo = vm.userInfo || {};

        activate();

        ///////

        function activate () {
            vm.state.useAccountAsBilling = true;
        }


        // todo: prevent form data being lost by storing it locally and retrieving as needed - ULM-83

        /**
         * Determines whether the form submission button should be enabled or disabled
         * @returns {boolean}
         */
        function cannotSubmitForm () {
            return vm.loading || vm.businessInfo.$invalid || vm.userInfo.$invalid;
        }

        /**
         * Called automatically when the stepper form is submitted.
         * Tokenize the credit card details before passing it along with the form
         * data to the processRegistration private method to finish the registration.
         * @param {number} status - http status code representing the result of the tokenization process
         * @param {Object} res - response object
         */
        function createBillingToken (status, res) {
            vm.billingErrors = [];

            if (status === 200) {
                vm.payload.token = res.id;
                vm.state.billingFormStatus = true;
                // nested within $timeout to ensure it's run after the $digest has completed and thus updated accountInfo.$valid
                $timeout(function () { processRegistration(); });
            } else {
                $log.error('token res.error:', res.error);
                alertService.new.error(res.error.message, 0);
                vm.state.billingFormStatus = false;
                vm.loading = false;
            }
        }

        function processRegistration () {
            // set loading state to hide the CTA and show a loading indicator
            vm.loading = true;

            // fixme: doesn't handle existing email/account (nothing happens)
            // fixme: if final stage of registration fails, there's still an active Stripe subscription created...

            // check validity of each of the sub-forms
            if (vm.businessInfo.$valid && vm.userInfo.$valid && vm.accountInfo.$valid) {
                $log.info('creating Stripe subscription.');
                vm.payload.plan = STRIPE_PLAN_ID;
                $log.info(vm.payload);
                billingService.createSubscription(vm.payload).then(createSubscriptionSuccess, createSubscriptionError);
            } else {
                vm.loading = false;
                alertService.new.error('Something went wrong, please try again', 0);
            }

            function createSubscriptionError (res) {
                vm.errors = res.data.errors;
                // fixme: if error doesn't have message, add a generic one
                $log.error('lambda error - res:', res);
                vm.billingFormStatus = false;
            }

            function createSubscriptionSuccess (res) {
                $log.info('Subscription created successfully...');
                console.log('this is the stripeSubscription response:', res);
                vm.user.billingData = {
                    customer_id: res.data.customer.id,
                    plan: 'price_1GwCJpLy1PJJoYmGJo2TaXkK',
                    billing_email: vm.payload.billing_email,
                };

                // todo: create a listener for window being closed to prevent with prompt() like gmail does to minimise incomplete registrations
                authService.register(vm.user)
                    .catch(function (error) {
                        vm.loading = false;
                        vm.error = error;
                    });
            }
        }

        /**
         * if the user has opted to use their account email as
         * their billing email, this function is run anytime
         * the account email is changed and updates the billing
         * email field to match
         */
        function evalSendValueToBilling () {
            if (vm.state.useAccountAsBilling) {
                vm.payload.billing_email = vm.user.email;
            }
        }

        /**
         * called when the user toggles the useAccountAsBilling state
         */
        function evalUseAccountAsBilling () {
            if (vm.state.useAccountAsBilling) {
                // update the value of billing email, updates are handled elsewhere
                vm.payload.billing_email = vm.user.email;
            } else {
                // clear billing email field
                vm.payload.billing_email = '';
            }
        }

        /**
         * used to disconnect the billing email from the account email
         * whenever the user changes the billing email field
         */
        function stopUsingAccountAsBilling () {
            vm.state.useAccountAsBilling = false;
        }

    }
}());
